import React from 'react';
import { RECIPE_DATA } from 'src/data/recipeData';
import Banner from './../images/slider_banners/banner.png';
import RecipeSlide from './../images/recipes/recipe_banner.png';
import defaultImage from '../images/default_image.png';
import { useGetRecipesQuery } from '../store/slices/api.slice';
import { Link } from 'react-router-dom';

const RecipeSection: React.FC<any> = () => {

  const {data: recipes, isLoading, isSuccess} = useGetRecipesQuery('')

  
  return (
    <section className='w-full'>
      {/* recipe banner */}
      <img
        src={Banner}
        alt='Banner slide'
        width={1000}
        height={1000}
        className='object-fill w-full h-full'
      />
      <div className='w-full lg:w-[80%] mx-auto mb-24 lg:my-14'>
        <h2 className='section-heading-2 mt-12 mb-7'>Recipe of the day</h2>
        {RECIPE_DATA.map((banner, index) => (
          <div
            key={index}
            className='w-full flex flex-col justify-between gap-y-8'
          >
            <div className='h-[30vh] md:h-[45vh]'>
              <img
                src={banner.bannerSrc === null ? banner.bannerSrc : RecipeSlide}
                alt={banner.title}
                width={1000}
                height={1000}
                className='object-cover h-full md:rounded-md lg:rounded-lg xl:rounded-3xl'
              />
            </div>

            <div className='flex flex-col justify-between gap-x-4 items-start mx-auto p-2'>
              <h2 className='my-6 font-semibold section-heading-2'>
                Recipe list
              </h2>
              <div
                key={banner.title}
                className='grid grid-cols-2 md:grid-cols-2 lg:grid-col xl:grid-cols-3 gap-4'
              >
                {isSuccess ? 
                
                (recipes.data.map((recipe: any) => (
                  <Link
                    key={recipe.id}
                    className='w-full h-fit bg-white flex rounded-xl shadow cursor-pointer'
                    to={`/recipe-details/${recipe.slug}`}
                  >
                    <img
                      src={
                        recipe.image === null
                          ? defaultImage
                          :
                          recipe.image
                          
                      }
                      alt={recipe.name}
                      width={1000}
                      height={1000}
                      className='object-cover w-[50%] h-full md:h-full rounded-xl'
                    />
                    <div className='w-[50%] md:py-4 md:ps-4 flex flex-col justify-center items-start truncate text-ellipsis ps-2 py-2'>
                      {/* <a
                        href={recipe.link}
                        className='flex items-center text-yellow-950 bg-yellow-300 text-ellipsis overflow-hidden px-1.5 text-xs font-medium rounded-full hover:bg-yellow-500'
                      >
                        {recipe.category}
                      </a> */}
                      <div className='text-start space-y-2 md:space-y-4'>
                        <h4 className='text-xs md:text-sm font-medium capitalize text-ellipsis overflow-hidden leading-tight'>
                          {recipe.title}
                        </h4>
                        {/* <h4 className='text-xs md:text-sm font-medium text-green-600'>
                          ₦ {recipe.price}
                        </h4> */}
                      </div>
                    </div>
                  </Link>
                )))
                :
                (
                  <div className='p-5'>
                    No Recipes
                  </div>
                ) 
              }
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default RecipeSection;
