import { useEffect, useState } from 'react';
import ProductGridSection from '../components/ProductGridSection';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import Slider from '../components/Slider';
import FeaturedCat from '../components/FeaturedCat';
import Hero from '../components/Hero';
import RecipeSection from '../components/RecipeSection';
import { RECIPE_DATA } from 'src/data/recipeData';
import ProductDataService from '../config/services/compositeProduct.service';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { useGetCompositeProductsQuery } from '../store/slices/api.slice';

const Home = () => {
  const {data: products, isLoading, isSuccess} = useGetCompositeProductsQuery('')
  
  return (
    <div>
      <MaxWidthWrapper>
        <Slider />
        <Hero />
        <FeaturedCat title="Featured Categories" />
        <ProductGridSection loading={isLoading} isSuccess={isSuccess} productData={isSuccess ? products.data : []}  />
      </MaxWidthWrapper>
      <RecipeSection
        title={RECIPE_DATA[0].title}
        link={RECIPE_DATA[0].link}
        bannerSrc={RECIPE_DATA[0].bannerSrc}
        recipes={RECIPE_DATA[0].recipes}
      />
    </div>
  );
};

export default Home;
