import { Controller } from "react-hook-form";

type InputProps = {
  control: any;
  rules: any;
  name: string;
  type: string;
  id: string;
  className?: string;
  placeholder: string;
};

const Input = ({
  control,
  type,
  name,
  id,
  className,
  placeholder,
  rules = {},
  ...props
}: InputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <>
          <input
            id={id}
            type={type}
            className={className ? className : "input-box"}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            {...props}
          />
        </>
      )}
    />
  );
};

export default Input;

export const ErrorInput = ({ error }: any) => {
  return (
    <div className="flex self-start ">
      {error && <p className="text-red-600 text-sm mt-[-13px]">{error || "Error"}</p>}
    </div>
  );
};
