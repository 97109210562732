import React, { useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Outlet, useLocation } from 'react-router-dom';

const AppLayout = () => {

  const location = useLocation();
  useEffect(()=> {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname])
  return (
    <div className='min-h-screen flex flex-col justify-between'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default AppLayout;
