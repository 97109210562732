
// import { useState } from 'react';
// import { Link } from 'react-router-dom';
// import AddressBtn from '../../components/Button';
import AccountService from '../../config/services/account.service';
import MaxWidthWrapper from '../../components/MaxWidthWrapper';
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../store/slices/auth.slice';
import { useChangePasswordMutation, useEditProfileMutation } from '../../store/slices/api.slice';


const LoginSecurity = () => {

  const navigate = useNavigate(), dispatch = useDispatch();
  const [close, setClose] = useState(false)
  const [closeName, setCloseName] = useState(false)
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [oldPassword, setOldPassword] = useState()
  const [password, setPassword] = useState()

  const userProfile: IProfile = useSelector((state: any) => state.auth.userProfile)
  const [editProfile] = useEditProfileMutation()
  const [changePassword] = useChangePasswordMutation()

  const EditName = async (e: any) => {
    e.preventDefault();
    setLoading(true)
    try {
      const values = {
        first_name: firstName,
        last_name: lastName
      }
      const responseData = await editProfile(values).unwrap();
      if(responseData.status){
        setLoading(false)
        setCloseName(false)
        await getProfile(dispatch).catch(err => console.log(err));
        navigate('/account/login-security')
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const editPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true)
    try {
      const values = {
        oldPassword,
        password
      }
      await changePassword(values).unwrap();
      setLoading(false)
      setClose(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <MaxWidthWrapper>
      <div className='order-page-header-wrapper'>
        <h3 className='text-base lg:text-2xl font-semibold'>Login and security</h3>
      </div>
      <section className='bg-white rounded-lg shadow'>
        <form className='w-full flex flex-col space-y-5 mt-5 mb-12 p-6 !text-gray-800'>
          <Dialog>
            <DialogTrigger onClick={() => setClose(true)} className='text-right w-fit text-gray-700 hover:bg-green-200 px-10 border-[1.5px] border-gray-700 text-sm font-medium rounded-full focus:outline-none focus:ring-4 focus:ring-green-300'>
              Change Password
            </DialogTrigger>
            {close &&
              <DialogContent>
                <DialogHeader>
                  <DialogTitle className='form-heading'>
                    Change Password
                  </DialogTitle>
                  <form className='w-full space-y-5 mt-5 mb-12 text-gray-800'>
                    <div className='w-full'>
                      <input
                        type='text'
                        id='type'
                        className='input-box'
                        placeholder='Old password'
                        value={oldPassword}
                        onChange={(e: any) => setOldPassword(e.target.value)}
                      />
                      <input
                        type='text'
                        id='type'
                        className='input-box'
                        placeholder='New password'
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                      />
                    </div>
                    {loading ? (
                      <button type="submit" className="checkout-btn">
                        <Loading loading={loading} />
                      </button>
                    ) : (
                      <button type="submit" onClick={editPassword} className="!w-fit checkout-btn">
                        Save changes
                        <span className="sr-only">Save changes</span>
                      </button>)}
                  </form>
                </DialogHeader>
              </DialogContent>}
          </Dialog>
          <div className='w-full'>
            <label htmlFor="fullName" className='input-label'>Full name</label>
            <Dialog>
              <DialogTrigger onClick={() => setCloseName(true)} className='dialogue-btn'>
                Edit
              </DialogTrigger>
              {closeName &&
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle className='form-heading'>
                      Update name
                    </DialogTitle>
                    <form className='w-full space-y-5 mt-5 mb-12 text-gray-800'>
                      <div className='w-full'>
                        <input
                          type='text'
                          id='type'
                          className='input-box'
                          placeholder='First Name'
                          value={firstName}
                          onChange={(e: any) => setFirstName(e.target.value)}
                        />
                        <input
                          type='text'
                          id='type'
                          className='input-box'
                          placeholder='Last Name'
                          value={lastName}
                          onChange={(e: any) => setLastName(e.target.value)}
                        />
                      </div>
                      {loading ? (
                        <button type="submit" className="checkout-btn">
                          <Loading loading={loading} />
                        </button>
                      ) : (
                        <button type="submit" onClick={EditName} className="!w-fit checkout-btn">
                          Save changes
                          <span className="sr-only">Save changes</span>
                        </button>)}
                    </form>
                  </DialogHeader>
                </DialogContent>}
            </Dialog>
            <p className='text-sm'>{userProfile.first_name} {userProfile.last_name}</p>
          </div>
          <div className='w-full'>
            <label htmlFor="email" className='input-label'>Email</label>
            <p className="text-sm">{userProfile.email}</p>
          </div>

          <div className='w-full'>
            <label htmlFor="phoneNo" className='input-label'>Phone number</label>
            <p className="text-sm">{userProfile.phone}</p>
          </div>
          <div className='w-full p-2 space-y-4 border-b-[1.5px] border-gray-900'>
            <span className='text-xs text-red-600 mb-3'>Delete account</span>
            <div className='flex items-center gap-x-3'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M10.03 3.659c.856-1.548 3.081-1.548 3.937 0l7.746 14.001c.83 1.5-.255 3.34-1.969 3.34H4.254c-1.715 0-2.8-1.84-1.97-3.34zM12.997 17A.999.999 0 1 0 11 17a.999.999 0 0 0 1.997 0m-.259-7.853a.75.75 0 0 0-1.493.103l.004 4.501l.007.102a.75.75 0 0 0 1.493-.103l-.004-4.502z" /></svg>
              <span className='text-xs'>
                Submit a request for us to close your TAIKEZ account, erasing your data permanently.
              </span>
            </div>
          </div>
        </form>
      </section>
    </MaxWidthWrapper>
  );
};

export default LoginSecurity;
