import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import Breadcrumb from './../components/Breadcrumb';
import ProductCard from './../components/ProductCard';
import ResetBtn from '../components/Button';
import categoryPhoto from '../images/pages/category_photo.png';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import { Slider } from '../components/ui/slider';
import PriceFormatter from '../config/formatters/priceFormatter';
import ProductsByCategoryService from '../config/services/productsByCategory.service';
import { useGetProductsByCategoryQuery } from '../store/slices/api.slice';

const CategoryPage = () => {
  const [sorted, setSorted] = useState(false);
  const {categorySlug} = useParams<string>();
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(20000);
  const [sort, setSort] = useState('');
  const {data: products, isLoading, isSuccess} = useGetProductsByCategoryQuery(`${categorySlug}`)


  const sortedProducts = useMemo(() => {
    if (isLoading || isSuccess == false) return [];
  
    if (sort === 'name') {
      return [...products.data].sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort === 'added') {
      return products.data; 
    } else {
      return products.data;
    }
  }, [isLoading, categorySlug, sort]);


  // const handlePriceChange = (e: any) => {
  //   const { value } = e.target;
  //   setMaxPrice(value);
  // };

  const handlePriceChange = (value: number[]) => {
    setMaxPrice(value[0]); // Update maxPrice based on slider value
  };

  const handleSortChange = (value: any) => {
    setSort(value);
  };

  const filterProductByPrice = sortedProducts.filter(
    (item: any) => item.price >= minPrice && item.price <= maxPrice
  );

  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: categorySlug ?? '', to: `/${categorySlug}` },
  ];

  const buttonItem = {
    link: '/',
    btnTxt: 'Reset',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
      >
        <g
          fill='none'
          fillRule='evenodd'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path d='M3.578 6.487A8 8 0 1 1 2.5 10.5' />
          <path d='M7.5 6.5h-4v-4' />
        </g>
      </svg>
    ),
  };

  return (
    <div className='mb-16'>
      <MaxWidthWrapper className='pb-10 bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
        <div className='w-full'>
          <img
            src={categoryPhoto}
            alt='pepper, corn, groundnut, spices and lemon on the table'
            width={1000}
            height={1000}
            className='object-cover w-full rounded-xl'
          />
        </div>
      </MaxWidthWrapper>

      <div className='flex flex-col justify-between gap-2 pt-4 lg:ps-10 xl:ps-20 lg:items-center'>
        <section className='lg:w-[25%] xl:w-[20%] mb-6'>
          <div className='flex items-center justify-between w-full text-center bg-green-500 lg:hidden'>
            <div className='py-2 space-y-1 text-start'>
              <label htmlFor='' className='px-2 mb-1 text-white'>
                <span className='font-semibold'>
                  ₦ {PriceFormatter(minPrice, false)} - ₦{' '}
                  {PriceFormatter(maxPrice, false)}
                </span>
              </label>
              <Slider
                value={[maxPrice]}
                min={0}
                max={20000}
                step={100}
                onValueChange={handlePriceChange}
                className='min-w-[150px] hover:cursor-pointer ms-2'
              />
            </div>
            <div>
              <Select onValueChange={handleSortChange}>
                <SelectTrigger className='flex w-[180px] !bg-transparent !border-transparent focus:border-none focus:ring-none focus:rounded-none text-sm ps-6 font-medium text-white'>
                  <SelectValue placeholder='Sort by' />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='name'>Sort alphabetically</SelectItem>
                  <SelectItem value='added'>Sort by time added</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </section>

        <section className='px-3 flex-1 md:px-6 lg:ps-0 xl:pe-20 lg:w-[70%] xl:w-[75%]'>
          <div className='hidden mb-6 text-sm lg:flex lg:flex-row lg:justify-between'>
            <div className='hidden lg:flex lg:flex-col lg:w-52'>
              <label htmlFor='' className='mb-2'>
                Filter By Price:{' '}
                <span className='font-semibold'>
                  ₦ {PriceFormatter(minPrice, false)} - ₦{' '}
                  {PriceFormatter(maxPrice, false)}
                </span>
              </label>
              <Slider
                value={[maxPrice]}
                min={0}
                max={20000}
                step={100}
                onValueChange={handlePriceChange}
                className='w-full hover:cursor-pointer'
              />
            </div>
            <Select onValueChange={handleSortChange}>
              <SelectTrigger className='w-[180px] !bg-transparent border-b-[1.5px] border-gray-400 !border-x-transparent !border-t-transparent !rounded-none focus:ring-0 !ring-offset-0 focus:rounded-none text-sm ps-6 font-medium text-gray-700'>
                <SelectValue placeholder='Sort by' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='name'>Sort alphabetically</SelectItem>
                <SelectItem value='added'>Sort by time added</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <ProductCard products={filterProductByPrice} />
        </section>
      </div>
    </div>
  );
};

export default CategoryPage;
