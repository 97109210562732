import { useState, useRef, useEffect } from 'react';
import cn from '../lib/utils';
import { SLIDER_DATA } from 'src/data/sliderData';

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const startTouchRef = useRef<number | null>(null);
  const endTouchRef = useRef<number | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!isPaused) {
        setActiveIndex((prevIndex) => (prevIndex + 1) % SLIDER_DATA.length);
      }
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPaused]);

  const handleIndicatorClick = (index: number) => {
    setActiveIndex(index);
    setIsPaused(true);
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? SLIDER_DATA.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === SLIDER_DATA.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    startTouchRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    endTouchRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (startTouchRef.current !== null && endTouchRef.current !== null) {
      const diff = startTouchRef.current - endTouchRef.current;
      if (diff > 50) {
        handleNextClick();
      } else if (diff < -50) {
        handlePrevClick();
      }
    }
    startTouchRef.current = null;
    endTouchRef.current = null;
  };

  return (
    <section>
      <div
        className='relative w-full h-[24vh] md:h-[40vh] lg:h-[50vh] mt-5 z-0'
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className='absolute top-0 left-0 w-full h-full'>
          {SLIDER_DATA.map((slider, index) => (
            <img
              key={index}
              src={slider.imageSrc}
              alt={slider.name}
              width={1000}
              height={1000}
              loading='eager'
              className={cn(
                'absolute shadow md:border-[2px] border-gray-700 top-0 left-0 w-full h-full rounded-md md:rounded-2xl xl:rounded-3xl object-cover transition-opacity',
                {
                  'opacity-100': activeIndex === index,
                  'opacity-0': activeIndex !== index,
                }
              )}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </div>
        <div className='absolute bottom-5 md:bottom-4 left-1/2 transform -translate-x-1/2'>
          {SLIDER_DATA.map((slider, index) => (
            <button
              key={index}
              onClick={() => handleIndicatorClick(index)}
              className={cn(
                'w-1.5 h-1.5 md:w-2 md:h-2 rounded-full mr-4 transition-colors',
                {
                  'bg-white': activeIndex !== index,
                  'bg-green-600': activeIndex === index,
                }
              )}
            />
          ))}
        </div>

        <button
          className='slideBtn hidden md:flex left-4 md:left-16 lg:left-24'
          style={{ opacity: isPaused ? 1 : 0 }}
          onClick={handlePrevClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            className='stroke-gray-800'
          >
            <path
              fill='currentColor'
              d='M12.353 15.854a.5.5 0 0 1-.707.001L6.162 10.39a.55.55 0 0 1 0-.78l5.484-5.464a.5.5 0 1 1 .706.708L7.188 10l5.164 5.147a.5.5 0 0 1 .001.707Z'
            />
          </svg>
        </button>

        <button
          className='slideBtn hidden md:flex right-4 md:right-16 lg:right-24'
          style={{ opacity: isPaused ? 1 : 0 }}
          onClick={handleNextClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            className='stroke-gray-800'
          >
            <path
              fill='currentColor'
              d='M7.646 4.147a.5.5 0 0 1 .707-.001l5.484 5.465a.55.55 0 0 1 0 .779l-5.484 5.465a.5.5 0 0 1-.706-.708L12.812 10L7.647 4.854a.5.5 0 0 1-.001-.707Z'
            />
          </svg>
        </button>
      </div>
    </section>
  );
};

export default Slider;
