import apiInstance from './axios.service';

const AuthService = {
  login(payload: ILogin, platform='CUSTOMER') {
    return apiInstance({
      method: 'post',
      url: 'auth-service/login',
      data: payload,
      headers: {platform}
    });
  },

  signup(payload: any) {
    return apiInstance({
      method: 'post',
      url: 'users-service/users/register',
      data: payload
    });
  },

  logout() {
    return apiInstance({
      method: 'GET',
      url: 'auth-service/logout'
    });
  }
}

export default AuthService;
