import { Link } from 'react-router-dom';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
// import { PRODUCTS } from '../data/products';
import Card from '../components/Card';
import CartSummary from '../components/CartSummary';
import Breadcrumb from '../components/Breadcrumb';
import { useEffect, useState } from 'react';
import CartService from '../config/services/cart.service';
import { useGetCartsQuery } from '../store/slices/api.slice';
import { useSelector } from 'react-redux';

const Checkout = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Cart', to: '/cart' },
    { label: 'Checkout', to: '#' },
  ];

 

  const {data: carts, isSuccess, refetch} = useGetCartsQuery('')
  const [selectedAddress, setSelectedAddress] = useState({})
  const userAddress = useSelector((state: any) => state.auth.userProfile)
  
  useEffect(() => {
    const defaultAddress = userAddress.addresses.find((address: any) => address.default == true )
    refetch()
    if (defaultAddress) {
      setSelectedAddress(defaultAddress)
    }
  }, [userAddress])

  return (
    <MaxWidthWrapper>
      <Breadcrumb items={breadcrumbItems} />
      <div className='flex flex-col lg:flex-row-reverse justify-between gap-x-8 md:gap-x-12 lg:gap-x-18 xl:gap-x-28 mb-16'>
        <CartSummary items={isSuccess ? carts.data : []} address={selectedAddress} isSuccess={isSuccess} />
        
        <div className='w-full space-y-5 md:space-y-10 lg:space-y-8'>
          <div className='flex items-center justify-between mb-3'>
            <h3 className='section-heading'>Delivery Details</h3>
            <Link
              to='/account/addresses'
              className='text-btn'
            >
              Change
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
              >
                <path
                  fill='currentColor'
                  fillRule='evenodd'
                  d='M21.455 5.416a.75.75 0 0 1-.096.943l-9.193 9.192a.75.75 0 0 1-.34.195l-3.829 1a.75.75 0 0 1-.915-.915l1-3.828a.778.778 0 0 1 .161-.312L17.47 2.47a.75.75 0 0 1 1.06 0l2.829 2.828a.756.756 0 0 1 .096.118m-1.687.412L18 4.061l-8.518 8.518l-.625 2.393l2.393-.625z'
                  clipRule='evenodd'
                />
                <path
                  fill='currentColor'
                  d='M19.641 17.16a44.4 44.4 0 0 0 .261-7.04a.403.403 0 0 1 .117-.3l.984-.984a.198.198 0 0 1 .338.127a45.91 45.91 0 0 1-.21 8.372c-.236 2.022-1.86 3.607-3.873 3.832a47.77 47.77 0 0 1-10.516 0c-2.012-.225-3.637-1.81-3.873-3.832a45.922 45.922 0 0 1 0-10.67c.236-2.022 1.86-3.607 3.873-3.832a47.75 47.75 0 0 1 7.989-.213a.2.2 0 0 1 .128.34l-.993.992a.402.402 0 0 1-.297.117a46.164 46.164 0 0 0-6.66.255a2.89 2.89 0 0 0-2.55 2.516a44.421 44.421 0 0 0 0 10.32a2.89 2.89 0 0 0 2.55 2.516c3.355.375 6.827.375 10.183 0a2.89 2.89 0 0 0 2.55-2.516'
                />
              </svg>
            </Link>
          </div>

          {/* <Card 
            heading='Pickup station'
            text='No pickup location selected'
            link='Select pickup location'
          /> */}

          <Card
            heading='Door delivery'
            text='No pickup location selected'
            link='Select pickup location'
            address={selectedAddress}
          />
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Checkout;
