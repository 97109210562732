import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import MaxWidthWrapper from '../../components/MaxWidthWrapper';
import { ORDER_DATA } from '../../data/orderData';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import AccountService from '../../config/services/account.service';
import { useGetOrdersQuery } from '../../store/slices/api.slice';
import taikezProductImage from '../../images/default_image.png';
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

const Orders = () => {
  const getStatusColor = (orderStatus: string) => {
    switch (orderStatus) {
      case 'paid':
        return '!bg-green-500'; // Green color for 'paid'
      case 'delivered':
        return 'bg-yellow-500'; // Yellow color for 'delivered'
      case 'cancelled':
        return 'bg-red-500'; // Red color for 'cancelled'
      case 'pending':
        return 'bg-blue-500'; // Blue color for 'pending'
      case 'ORDER_CONFIRMED':
        return 'bg-blue-500'; // Blue color for 'pending'
      default:
        return ''; // Default color or no color
    }
  };
  const { data: orders, isSuccess } = useGetOrdersQuery('')

  const dateSetter = (data: any) => {
    const dateTime = new Date(data);
    const datePart = dateTime.toLocaleDateString(undefined, { month: "short", day: "numeric", year: '2-digit' });
    const timePart = dateTime.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });;
    const result = `${datePart} ${timePart}`;
    return result
  }

  return (
    <MaxWidthWrapper>
      <div className='flex-col order-page-header-wrapper'>
        <h3 className='text-base lg:text-2xl font-semibold mt-3 whitespace-nowrap !mb-5'>
          Orders
        </h3>
        <Table className='hidden w-full overflow-x-auto table-auto lg:table-row'>
          <TableHeader className='!text-gray-900 !font-normal !pt-8'>
            <TableRow className='bg-white hover:bg-white'>
              <TableHead className='order-heading rounded-s-full'>
                Item name
              </TableHead>
              <TableHead className='order-heading'>Order no.</TableHead>
              <TableHead className='order-heading'>Date</TableHead>
              <TableHead className='order-heading rounded-r-full'>Amount(₦)</TableHead>
              {/* <TableHead className='text-left rounded-r-full order-heading'>
                Status
              </TableHead> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            {isSuccess ?
              orders.data.map((item: any, index: any) => (
                    <TableRow
                      key={index}
                      className='text-left cursor-pointer capitalize hover:!bg-transparent'
                    >
                      <TableCell className='flex items-center gap-x-4 lg:!min-w-[200px]'>
                <Dialog>
                  <DialogTrigger >
                  <div  className='flex items-center gap-x-4 lg:!min-w-[200px]'>
                        <img
                          src={item.product.image[0] || taikezProductImage}
                          alt={item.name}
                          width={1000}
                          height={1000}
                          className='object-cover w-[50px] h-[50px] rounded-full'
                        />
                        {item.product.name}
                  </div>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle className='form-heading'>
                        Order History #{item.reference}
                      </DialogTitle>
                      <div>

                        <div className='space-y-4'>
                          <p className='cart-summary-li'>
                            <span className='text-gray-600'>Product</span>
                            <span className='cart-value'>
                              {item.product.name}  ({item.quantity} {item.quantity > 1 ? 'items' : 'item'})
                            </span>
                          </p>
                          <p className='cart-summary-li'>
                            <span className='text-gray-600'>Reference</span>
                            <span className='cart-value'>
                              #{item.reference}
                            </span>
                          </p>
                          <p className='cart-summary-li'>
                            <span className='text-gray-600'>Date</span>
                            <span className='cart-value'>
                              {dateSetter(item.updated_at)}
                            </span>
                          </p>
                          <p className='cart-summary-li'>
                            <span className='text-sm font-semibold text-gray-600'>Amount</span>
                            <span className='cart-value'>
                              ₦{item.amount}

                            </span>
                          </p>
                        </div>
                      </div>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
                      </TableCell>
                      <TableCell>{item.reference}</TableCell>
                      <TableCell className='whitespace-nowrap'>
                        {dateSetter(item.updated_at)}
                      </TableCell>
                      <TableCell>{item.amount}</TableCell>
                      {/* <TableCell className='text-left'>
                  <p
                    className={`w-F text-xs text-center py-2 rounded-full text-white ${getStatusColor(
                      item.status
                    )}`}
                  >
                    {item.status}
                  </p>
                </TableCell> */}
                    </TableRow>
              )) :
              <div className='flex items-center justify-center w-full py-20 text-sm text-gray-500 flex-row text-center'><p className='text-center'>
                No Orders</p>
              </div>
            }
          </TableBody>
        </Table>

        {/* mobile divs start here */}
        {isSuccess ?
          orders.data.map((item: any, index: any) => (
            <Dialog>
            <DialogTrigger>
            <div
              key={index}
              className='flex lg:hidden p-2 !gap-y-14 mb-4 bg-white rounded-md shadow !text-left justify-between items-center'
            >
              <div className='w-[40%]'>
                <img
                  src={item.product.image[0] || taikezProductImage}
                  alt='a box icon'
                  width={1000}
                  height={1000}
                  className='object-cover w-[60px] h-[60px] rounded-full'
                />
              </div>
              <div className='w-full space-y-2 text-sm text-left'>
                <p className='font-medium'>{item.product.name}</p>
                <p className='text-xs'>#{item.reference}</p>
              </div>
              <div className='w-[45%] space-y-2 text-left'>
                <p className='text-xs'>{dateSetter(item.updated_at)}</p>
                <p className='text-sm font-medium'>₦{item.amount}</p>
              </div>
              {/* <div className='w-[40%] text-xs font-medium px-2 capitalize text-left'>
              <p
                className={`text-center py-1.5 rounded-full text-white ${getStatusColor(
                  item.orderStatus
                )}`}
              >
                {item.orderStatus}
              </p>
            </div> */}
            </div>
            </DialogTrigger>
            <DialogContent>
                    <DialogHeader>
                      <DialogTitle className='form-heading'>
                        Order History #{item.reference}
                      </DialogTitle>
                      <div>

                        <div className='space-y-4'>
                          <p className='cart-summary-li'>
                            <span className='text-gray-600'>Product</span>
                            <span className='cart-value'>
                              {item.product.name}  ({item.quantity} {item.quantity > 1 ? 'items' : 'item'})
                            </span>
                          </p>
                          <p className='cart-summary-li'>
                            <span className='text-gray-600'>Reference</span>
                            <span className='cart-value'>
                              #{item.reference}
                            </span>
                          </p>
                          <p className='cart-summary-li'>
                            <span className='text-gray-600'>Date</span>
                            <span className='cart-value'>
                              {dateSetter(item.updated_at)}
                            </span>
                          </p>
                          <p className='cart-summary-li'>
                            <span className='text-sm font-semibold text-gray-600'>Amount</span>
                            <span className='cart-value'>
                              ₦{item.amount}

                            </span>
                          </p>
                        </div>
                      </div>
                    </DialogHeader>
                  </DialogContent>
            </Dialog>
          )) :
          <div className='lg:hidden flex items-center justify-center w-full py-20 text-sm text-gray-500 flex-column text-center'><p className='text-center'>
            No Orders</p>
          </div>
        }
      </div>
    </MaxWidthWrapper>
  );
};

export default Orders;
