import axios, {AxiosHeaders} from "axios";
import {AppInfo, AppKeys} from "../utils/constants";
import TokenHelper from "../utils/token.helper";
import CookieHelper from "../utils/cookie.helper";


export const LOCALHOST = "localhost";
export const BETA_BASEURL = "";
export const PROD_BASEURL = "";

export const getBaseUrl = (): string => {
  let baseURL = 'https://taikez-staging-8607b05717bf.herokuapp.com/apis/v1';
  if (typeof window !== "undefined") {
    if (window.location.hostname === LOCALHOST || window.location.hostname === BETA_BASEURL) baseURL = AppInfo.BASE_STAGING_URL
    else if (window.location.hostname === PROD_BASEURL) baseURL = AppInfo.BASE_URL
    return baseURL;
  } else return baseURL;
};

const headers = {"Content-Type": "application/json"}
const apiInstance = axios.create({baseURL: getBaseUrl(), headers})

apiInstance.interceptors.request.use(config => {
  const token = TokenHelper.getToken(), headers = {Authorization: `Bearer ${token}`, ...config.headers}
  if (TokenHelper.authenticationValid()) config.headers = headers as AxiosHeaders['headers'];
  else config.headers = {...config.headers} as AxiosHeaders['headers'];
  return config;
}, error => Promise.reject(error));

apiInstance.interceptors.response.use(response => response, error => {
  const token = TokenHelper.getToken();
  if (error.response.status === 401 && token) {
    CookieHelper.remove(AppKeys.TOKEN);
    // window.location.href = '/login';
  } else return error.response;
});

export default apiInstance;
