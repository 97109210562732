import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';

const breadcrumbItems = [
  { label: 'Home', to: '/' },
  { label: 'Account', to: '/account' },
  { label: 'Order', to: '/order' },
];

export default function RootLayout() {
  const [clickedLink, setClickedLink] = useState('');

  const handleLinkClick = (link: string) => {
    setClickedLink(link);
  };

  return (
    <main className='text-gray-900 mb-8'>
      <div className='w-full flex justify-center mb-4 py-2 lg:py-4'>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <div className='w-full flex justify-between'>
        <aside
          id='logo-sidebar'
          className='hidden lg:flex sticky top-28 z-0 w-52 xl:w-64 h-screen'
          aria-label='Sidebar'
        >
          <div className='h-full pb-4 overflow-y-auto'>
            <ul className='space-y-2 font-medium'>
              <li>
                <Link
                  to='/account/orders'
                  className={`order-link group ${
                    clickedLink === '/account/orders' ? 'activeTab' : ''
                  }`}
                  onClick={() => handleLinkClick('/account/orders')}
                >
                  <span className='order-svg '>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 32 32'
                      className='group-hover:text-white'
                    >
                      <path
                        fill='currentColor'
                        d='M4 5v6h1v16h22V11h1V5zm2 2h20v2H6zm1 4h18v14H7zm5.813 2A1 1 0 0 0 13 15h6a1 1 0 1 0 0-2h-6a1 1 0 0 0-.094 0a1 1 0 0 0-.094 0z'
                      />
                    </svg>
                  </span>
                  <span
                    className={`order-span ${
                      clickedLink === '/account/orders' ? 'activeTab' : ''
                    }`}
                  >
                    Orders
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to='/account/addresses'
                  className={`order-link group ${
                    clickedLink === '/account/addresses' ? 'activeTab' : ''
                  }`}
                  onClick={() => handleLinkClick('/account/addresses')}
                >
                  <span className='order-svg'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 28 28'
                      className='group-hover:text-white'
                    >
                      <path
                        fill='currentColor'
                        d='M14 2.25A9.75 9.75 0 0 1 23.75 12c0 4.12-2.895 8.61-8.61 13.518a1.75 1.75 0 0 1-2.283-.002l-.378-.328C7.017 20.408 4.25 16.028 4.25 12A9.75 9.75 0 0 1 14 2.25m0 1.5A8.25 8.25 0 0 0 5.75 12c0 3.502 2.548 7.537 7.714 12.057l.373.323a.25.25 0 0 0 .326 0c5.416-4.652 8.087-8.795 8.087-12.38A8.25 8.25 0 0 0 14 3.75m0 4.5a3.75 3.75 0 1 1 0 7.5a3.75 3.75 0 0 1 0-7.5m0 1.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5'
                      />
                    </svg>
                  </span>
                  <span
                    className={`order-span ${
                      clickedLink === '/account/addresses' ? 'activeTab' : ''
                    }`}
                  >
                    Addresses
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to='/account/coupons'
                  className={`order-link group ${
                    clickedLink === '/account/coupons' ? 'activeTab' : ''
                  }`}
                  onClick={() => handleLinkClick('/account/coupons')}
                >
                  <span className='order-svg'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='22.5'
                      height='22.5'
                      viewBox='0 0 14 14'
                      className='group-hover:text-white'
                    >
                      <g
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <path d='M.5 11a1 1 0 0 0 .998 1h11.004a1 1 0 0 0 .998-1V8.966a2.037 2.037 0 0 1 0-3.932V3a1 1 0 0 0-.998-1H1.498A1 1 0 0 0 .5 3v2.03a2.037 2.037 0 0 1 0 3.94zm4.02-1.5l5-5' />
                        <path d='M5.02 5.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1m4 4a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1' />
                      </g>
                    </svg>
                  </span>
                  <span
                    className={`order-span ${
                      clickedLink === '/account/coupons' ? 'activeTab' : ''
                    }`}
                  >
                    Coupons
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to='/account/saved-items'
                  className={`order-link group ${
                    clickedLink === '/account/saved-items' ? 'activeTab' : ''
                  }`}
                  onClick={() => handleLinkClick('/account/saved-items')}
                >
                  <span className='order-svg'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 36 36'
                      className='group-hover:text-white'
                    >
                      <path
                        fill='currentColor'
                        d='M18 32.43a1 1 0 0 1-.61-.21C11.83 27.9 8 24.18 5.32 20.51C1.9 15.82 1.12 11.49 3 7.64c1.34-2.75 5.19-5 9.69-3.69A9.87 9.87 0 0 1 18 7.72a9.87 9.87 0 0 1 5.31-3.77c4.49-1.29 8.35.94 9.69 3.69c1.88 3.85 1.1 8.18-2.32 12.87c-2.68 3.67-6.51 7.39-12.07 11.71a1 1 0 0 1-.61.21M10.13 5.58A5.9 5.9 0 0 0 4.8 8.51c-1.55 3.18-.85 6.72 2.14 10.81A57.13 57.13 0 0 0 18 30.16a57.13 57.13 0 0 0 11.06-10.83c3-4.1 3.69-7.64 2.14-10.81c-1-2-4-3.59-7.34-2.65a8 8 0 0 0-4.94 4.2a1 1 0 0 1-1.85 0a7.93 7.93 0 0 0-4.94-4.2a7.31 7.31 0 0 0-2-.29'
                        className='clr-i-outline clr-i-outline-path-1'
                      />
                      <path fill='none' d='M0 0h36v36H0z' />
                    </svg>
                  </span>
                  <span
                    className={`order-span ${
                      clickedLink === '/account/saved-items' ? 'activeTab' : ''
                    }`}
                  >
                    Saved items
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to='/account/login-security'
                  className={`order-link group ${
                    clickedLink === '/account/login-security' ? 'activeTab' : ''
                  }`}
                  onClick={() => handleLinkClick('/account/login-security')}
                >
                  <span className='order-svg'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 16 16'
                      className='group-hover:text-white'
                    >
                      <path
                        fill='currentColor'
                        fillRule='evenodd'
                        d='M8.246 14.713a27.792 27.792 0 0 1-1.505-.953c-.501-.34-.983-.707-1.444-1.1c-.458-.395-.888-.82-1.288-1.274c-.4-.455-.753-.95-1.05-1.478a7.8 7.8 0 0 1-.7-1.69A7.041 7.041 0 0 1 2 6.3V3.1l.5-.5c.333 0 .656-.011.97-.036c.296-.023.591-.066.882-.128c.284-.062.562-.148.832-.256c.284-.118.557-.261.816-.427a4.83 4.83 0 0 1 1.184-.565a4.8 4.8 0 0 1 2-.142a4.018 4.018 0 0 1 1.237.383c.199.097.392.204.58.322c.26.167.535.31.821.428c.27.109.547.194.831.256c.291.062.587.106.884.129c.311.024.634.035.967.035l.5.5v3.2a7.043 7.043 0 0 1-.256 1.919a7.804 7.804 0 0 1-.7 1.69a8.751 8.751 0 0 1-1.05 1.478c-.4.452-.829.877-1.286 1.27a15.94 15.94 0 0 1-1.448 1.1a28.71 28.71 0 0 1-1.51.956h-.508zM3 3.59V6.3c-.004.555.07 1.11.22 1.645a6.7 6.7 0 0 0 .61 1.473c.263.467.575.905.93 1.308c.37.417.766.81 1.188 1.174c.432.368.883.712 1.352 1.03c.4.267.8.523 1.2.769c.4-.242.8-.498 1.2-.768c.47-.319.923-.663 1.355-1.031c.421-.364.817-.756 1.186-1.172a7.8 7.8 0 0 0 .93-1.308c.261-.465.466-.96.61-1.473c.15-.537.223-1.09.22-1.647V3.59c-.159 0-.313-.012-.465-.023l-.079-.006a7.95 7.95 0 0 1-1.018-.147a6.112 6.112 0 0 1-1.976-.814a5.166 5.166 0 0 0-.482-.27a3.123 3.123 0 0 0-.943-.29a3.686 3.686 0 0 0-1.558.106c-.332.108-.649.26-.94.452c-.312.2-.64.372-.983.513a6.4 6.4 0 0 1-1 .307c-.335.07-.675.12-1.017.146c-.174.01-.355.02-.54.026m6.065 4.3a1.5 1.5 0 1 0-1.13 0L7.5 10.5h2z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </span>
                  <span
                    className={`order-span ${
                      clickedLink === '/account/login-security'
                        ? 'activeTab'
                        : ''
                    }`}
                  >
                    Login security
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>

        <div className='flex justify-center overflow-x-auto w-full lg:w-[75%] xl:w-full'>
          <Outlet />
        </div>
      </div>
    </main>
  );
}
