import { Link } from 'react-router-dom';
import CreateBtn from '../../components/Button';
import blackLogo from '../../images/logos/black_logo.svg';

const Auth5 = () => {
  const buttonItem = {
    btnTxt: 'Start shopping',
    link: '/',
    svg: '',
  };
  return (
    <section className='authPageWrapper'>
      <div className='w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] flex justify-end'></div>
      <div className='w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%] flex flex-col items-center'>
        <Link to='/' className='py-4'>
          <img
            src={blackLogo}
            alt='Taikez ecommerce logo'
            width={300}
            height={300}
            className='w-[7rem]'
          />
        </Link>
        <h4 className='text-3xl font-bold mt-4 mb-3'>🎉</h4>
        <p className='lg:text-lg xl:text-xl font-normal text-center'>
          Your account has been created!
        </p>
        <div className='w-full flex flex-col items-center space-y-8 my-7'>
          <p className='whitespace-nowrap'>
            You will be redirect in <span className='text-green-600'>5</span>
          </p>
        </div>
        <div className='w-[50%]'>
          <CreateBtn item={buttonItem} />
        </div>
      </div>
      <div className='w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] '></div>
    </section>
  );
};

export default Auth5;
