export const RECIPE_DATA = [
  {
    title: 'fresh plantain porriage',
    link: '/',
    bannerSrc: '/images/recipes/recipe_banner.png',
    recipes: [
      {
        id: 1,
        name: 'Beef',
        category: 'Meat',
        link: '/',
        price: '2,300',
        imageSrc: '/images/carousel/beef.jpg',
      },
      {
        id: 2,
        name: 'Groundnut oil',
        category: 'Oils',
        link: '/',
        price: '3,400',
        imageSrc: '/images/carousel/groundnuts.jpg',
      },
      {
        id: 3,
        name: 'Oranges',
        category: 'Fruits',
        link: '/',
        price: '4,000',
        imageSrc: '/images/carousel/oranges.jpg',
      },
      {
        id: 4,
        name: 'Rice',
        category: 'Grain',
        link: '/',
        price: '2,300',
        imageSrc: '/images/carousel/rice.webp',
      },
      {
        id: 5,
        name: 'Fresh mango juice',
        category: 'Fruits',
        link: '/',
        price: '5,400',
        imageSrc: '/images/carousel/mangos.jpg',
      },
      {
        id: 6,
        name: 'Millet',
        category: 'Grains',
        link: '/',
        price: '3,400',
        imageSrc: '/images/carousel/millet.jpg',
      },
    ],
  },
];
