import TokenHelper from '../../config/utils/token.helper'
import { getBaseUrl } from '../../config/services/axios.service'
import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'



const apiBaseQuery: BaseQueryFn = fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: (headers: Headers) => {
        const token = TokenHelper.getToken()
        headers.set("Content-Type", 'application/json')
        if (token) headers.set("Authorization", `Bearer ${token}`)
        return headers
      }
})

export const apiQuery = createApi({
    baseQuery: apiBaseQuery,
    reducerPath: 'apiQuery',
    tagTypes: ['Category', 'Cart', 'Payment', 'Products', 'Orders', 'Profile', 'Wishlist', 'Address', 'Recipe'],
    endpoints: builder => ({
        getCategories: builder.query({
            query: filter => `inventory-service/categories`,
            providesTags: ['Category']
        }),
        getCarts: builder.query({
            query: filter => `inventory-service/cart`,
            providesTags: ['Cart']
        }),
        postCart: builder.mutation({
            query: (body) => ({url: 'inventory-service/cart', method: 'POST', body}),
            invalidatesTags: ['Cart']
        }),
        removeCart: builder.mutation({
            query: (body) => ({url: `inventory-service/cart/remove-cart-item/${body}`, method: 'DELETE'}),
            invalidatesTags: ['Cart']
        }),
        addWishList: builder.mutation({
            query: (body) => ({url: 'inventory-service/wishlist', method: 'POST', body}),
            invalidatesTags: ['Wishlist']
        }),
        getCompositeProducts: builder.query({
            query: filter => 'inventory-service/products/composite',
            providesTags: ['Products']
        }),
        getProductsByCategory: builder.query({
            query: filter => `inventory-service/products/category/${filter}`,
            providesTags: ['Products']
        }),
        getProduct: builder.query({
            query: filter => `inventory-service/products/product/${filter}`,
            providesTags: ['Products']
        }),
        getRecipes: builder.query({
            query: filter => `inventory-service/recipes`,
            providesTags: ['Recipe']
        }),
        getRecipe: builder.query({
            query: filter => `inventory-service/recipes/${filter}`,
            providesTags: ['Recipe']
        }),
        addAddress: builder.mutation({
            query: (body) => ({url: 'users-service/users/profile/update-address', method: 'PATCH', body}),
            invalidatesTags: ['Address']
        }),
        removeAddress: builder.mutation({
            query: (body) => ({url: `users-service/users/profile/remove-address/${body}`, method: 'PATCH'}),
            invalidatesTags: ['Address']
        }),
        addDefaultAddress: builder.mutation({
            query: (body) => ({url: `users-service/users/profile/default-address/${body}`, method: 'PATCH'}),
            invalidatesTags: ['Address']
        }),
        editProfile: builder.mutation({
            query: (body) => ({url: 'users-service/users/profile/update', method: 'PATCH',  body}),
            invalidatesTags: ['Profile']
        }),
        changePassword: builder.mutation({
            query: (body) => ({url: `users-service/users/profile/change-password`, method: 'PATCH', body}),
            invalidatesTags: ['Profile']
        }),
        getOrders: builder.query({
            query: filter => `payment-service/orders`,
            providesTags: ['Orders']
        }),
        confirmPayment: builder.mutation({
            query: (body) => ({url: 'inventory-service/cart/confirm-payment', method: 'POST',  body}),
            invalidatesTags: ['Payment']
        }),
        searchProduct: builder.mutation({
            query: (body) => ({url: 'inventory-service/products/search', method: 'POST',  body}),
            invalidatesTags: ['Products']
        }),
        getWishList: builder.query({
            query: filter => `inventory-service/wishlist`,
            providesTags: ['Wishlist']
        }),
        removeWishlist: builder.mutation({
            query: (body) => ({url: `inventory-service/wishlist/${body}`, method: 'DELETE'}),
            invalidatesTags: ['Wishlist']
        }),
        
    })
})

export const {
    useGetCategoriesQuery,
    useGetCartsQuery,
    useGetCompositeProductsQuery,
    useGetProductsByCategoryQuery,
    useGetProductQuery,
    usePostCartMutation,
    useAddWishListMutation,
    useGetRecipesQuery,
    useGetRecipeQuery,
    useAddAddressMutation,
    useRemoveAddressMutation,
    useAddDefaultAddressMutation,
    useEditProfileMutation,
    useChangePasswordMutation,
    useGetOrdersQuery,
    useConfirmPaymentMutation,
    useSearchProductMutation,
    useGetWishListQuery,
    useRemoveWishlistMutation,
    useRemoveCartMutation,
} = apiQuery