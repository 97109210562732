import React, { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';

const AlertModal = ({ msg, click }: any) => {
  return (
    <Alert className='fixed z-30 rounded-md w-fit top-16 right-20'>
      <div className='flex items-center justify-between w-full px-4 text-sm gap-x-4'>
        <p>{msg}</p>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-5 h-5 text-green-600 hover:text-green-700 hover:cursor-pointer'
          viewBox='0 0 20 20'
          onClick={click}
        >
          <path
            fill='currentColor'
            d='M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z'
          />
        </svg>
      </div>
    </Alert>
  );
};

export default AlertModal;
