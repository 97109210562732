import React, { useEffect, useState } from 'react';
import MaxWidthWrapper from '../../components/MaxWidthWrapper';
import AccountService from '../../config/services/account.service';

const COUPONS = [
  {
    id: 1,
    name: 'coupon name 1',
    balance: 'Coupon balance (20% off) 1',
    createdAt: 'Date it was given (03/26) 1',
    desc: 'Description of the coupon explaining how it was gotten (Based on service or special event or gift) 1',
  },
  {
    id: 2,
    name: 'coupon name 2',
    balance: 'Coupon balance (20% off) 2',
    createdAt: 'Date it was given (03/26) 2',
    desc: 'Description of the coupon explaining how it was gotten (Based on service or special event or gift) 2',
  },
  {
    id: 3,
    name: 'coupon name 3',
    balance: 'Coupon balance (20% off) 3',
    createdAt: 'Date it was given (03/26) 3',
    desc: 'Description of the coupon explaining how it was gotten (Based on service or special event or gift) 3',
  },
  {
    id: 4,
    name: 'coupon name 4',
    balance: 'Coupon balance (20% off) 4',
    createdAt: 'Date it was given (03/26) 4',
    desc: 'Description of the coupon explaining how it was gotten (Based on service or special event or gift) 4',
  },
  {
    id: 5,
    name: 'coupon name 5',
    balance: 'Coupon balance (20% off) 5',
    createdAt: 'Date it was given (03/26) 5',
    desc: 'Description of the coupon explaining how it was gotten (Based on service or special event or gift) 5',
  },
];

const Coupons = () => {
  const [coupons, setCoupons] = useState<any[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<any>({});

  useEffect(() => {
    getCoupons();
  }, [coupons]);

  const getCoupons = async () => {
    try {
      const { data: responseData } = await AccountService.getCoupons();
      if (responseData.status === true) {
        setCoupons(responseData.data);
      } else {
        responseData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MaxWidthWrapper className='h-[60vh] lg:h-[50vh] overflow-y-auto'>
      <div className='flex-col order-page-header-wrapper'>
        <h3 className='text-base lg:text-2xl font-semibold mt-3 whitespace-nowrap !mb-5'>
          Coupons
        </h3>
        {/* <Dialog>
          <DialogTrigger className='w-fit h-[44px] checkout-btn'>
            Add new card
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"><circle cx="128" cy="128" r="112"/><path d="M 79.999992,128 H 176.0001"/><path d="m 128.00004,79.99995 v 96.0001"/></g></svg>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className='form-heading'>
                New message
              </DialogTitle>
              <form className='w-full mt-5 mb-12 space-y-5 text-gray-800'>
                <div className='w-full'>
                  <input
                    type='text'
                    id='type'
                    className='input-box'
                    placeholder='Subject'
                  />
                </div>
                <textarea id="chat" rows={4} className="resize-none input-box min-h-[50vh] focus-visible:!outline-none" placeholder="Message"></textarea>
                <button type="submit" className="!w-fit checkout-btn">
                  <span>Send</span>
                  <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                  </svg>
                  <span className="sr-only">Send message</span>
                </button>
              </form>
            </DialogHeader>
          </DialogContent>
        </Dialog> */}
      </div>
      {coupons.length > 0 ? (
        coupons.map((message: any, index) => (
          <section className='flex justify-between md:space-x-6 xl:space-x-8 rounded-3xl'>
            <div className='w-full lg:w-[55%] xl:w-[50%] h-full overflow-hidden'>
              <div className='w-full lg:h-[62vh] pe-4 space-y-4 pb-3 overflow-y-auto scroll-style'>
                <div
                  key={index}
                  className={`flex items-center w-full rounded-2xl space-x-4 drop-shadow bg-white px-3 py-5 hover:bg-green-200 cursor-pointer ${
                    selectedMessage === message ? '!bg-green-200' : ''
                  }`}
                  onClick={() => setSelectedMessage(message)}
                >
                  <div>
                    <h4 className='text-xs font-semibold capitalize xl:text-sm'>
                      {message.name}
                    </h4>
                    <p className='mt-2 text-xs font-medium'>
                      {message.balance}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='justify-center hidden w-full min-h-full overflow-y-auto lg:flex'>
              {selectedMessage > 0 ? (
                <div className='flex items-center w-full px-6 py-5 bg-white cursor-pointer gap-x-8 h-fit rounded-xl drop-shadow hover:bg-green-200'>
                  <div className='space-y-3'>
                    <h4 className='text-xs font-semibold capitalize xl:text-sm lg:text-justify'>
                      {selectedMessage.name}
                    </h4>
                    <p className='text-xs w-[70%]'>{selectedMessage.desc}</p>
                    <p className='mt-2 text-xs font-semibold lg:text-justify'>
                      {selectedMessage.balance}
                    </p>
                    <p className='mt-2 text-xs text-gray-400 lg:text-justify'>
                      {selectedMessage.createdAt}
                    </p>
                  </div>
                  <button
                    type='button'
                    className='text-sm font-medium text-green-600 hover:text-green-700'
                  >
                    Redeem
                  </button>
                </div>
              ) : (
                <div className='flex items-center justify-center w-full text-sm text-center text-gray-400 rounded-lg h-fit flex-column'>
                  No saved cards yet.
                </div>
              )}
            </div>
          </section>
        ))
      ) : (
        <div className='flex items-center justify-center w-full py-20 text-sm text-center text-gray-500 rounded-lg flex-column'>
          Coupons unavailable
        </div>
      )}
    </MaxWidthWrapper>
  );
};

export default Coupons;
