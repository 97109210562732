// import { Link } from 'react-router-dom';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
// import { PRODUCTS } from '../data/products';
import CartSummary from '../components/CartSummary';
import Breadcrumb from '../components/Breadcrumb';
import AddressBtn from '../components/Button';
import {
  Dialog,
  DialogContent,
//   DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";

const buttonItem = {
  link: '/',
  btnTxt: 'Add address',
};

const CheckoutAddress = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Cart', to: '/cart' },
    { label: 'Checkout', to: '#' },
  ];

  const cartItems = [
    {
      title: 'Cart Summary',
      deliveryTxt: 'Delivery fee',
      subTotalTxt: 'Sub total',
      couponTxt: 'Coupon',
      totalTxt: 'Total',
      deliveryFee: 20000,
      subTotalAmt: 20000,
      couponAmt: 10000,
      totalAmt: 10000,
      btnTxt: 'Pay now',
      link: 'payment',
      productLink: 'item',
      optionalTxt: '',
    }
  ]
  return (
    <MaxWidthWrapper>
      <Breadcrumb items={breadcrumbItems} />
      <div className='flex flex-col lg:flex-row-reverse justify-between gap-x-8 md:gap-x-12 lg:gap-x-18 xl:gap-x-28 mb-16'>
        <CartSummary items={cartItems} />
        <div className='w-full space-y-5 md:space-y-10 lg:space-y-8'>
          <div className='flex justify-between items-center mb-3'>
            <h3 className='section-heading'>Delivery Address</h3>
            <Dialog>
              <DialogTrigger className='text-btn'>
                Add address
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="1.5em" 
                  height="1.5em" 
                  viewBox="0 0 1024 1024"
                >
                  <path 
                    fill="currentColor" 
                    d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0m0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01M736 480H544V288c0-17.664-14.336-32-32-32s-32 14.336-32 32v192H288c-17.664 0-32 14.336-32 32s14.336 32 32 32h192v192c0 17.664 14.336 32 32 32s32-14.336 32-32V544h192c17.664 0 32-14.336 32-32s-14.336-32-32-32"
                  />
                </svg>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle className='form-heading'>
                    Add address 
                  </DialogTitle>
                  <form className='w-full flex flex-col space-y-5 mt-5 mb-12 !text-gray-800'>
                    <div className='w-full'>
                      <label htmlFor="fullName" className='input-label'>Full name</label>
                      <input
                        type='text'
                        id='fullName'
                        className='input-box'
                        placeholder='Full name'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor="phoneNo" className='input-label'>Phone number</label>
                      <input
                        type='text'
                        id='phoneNo'
                        className='input-box'
                        placeholder='Phone number'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor="address1" className='input-label'>Address 1</label>
                      <input
                        type='text'
                        id='address1'
                        className='input-box'
                        placeholder='Address 1'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor="address2" className='input-label'>Address 2</label>
                      <input
                        type='text'
                        id='address2'
                        className='input-box'
                        placeholder='Address 2'
                      />
                    </div>
                    <div className='w-full flex space-x-6'>
                      <select id="city" className="input-box !text-xs">
                        <option defaultValue="">City</option>
                        <option value="abuja">Germany</option>
                      </select>
                      <select id="state" className="input-box !text-xs">
                        <option defaultValue="">State</option>
                        <option value="abuja">Germany</option>
                      </select>
                      <input
                        type='text'
                        id='zipCode'
                        className='input-box placeholder:!text-xs'
                        placeholder='Zip code'
                      />
                    </div>
                    <div className='w-[50%]'><AddressBtn item={buttonItem} /></div>
                  </form>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </div>
          <div className='w-full p-4 py-6 space-y-1 md:space-y-4 bg-white rounded-3xl'>
            <div className='flex gap-x-4'>
              <input
                id='inline-radio'
                type='radio'
                value=''
                name='inline-radio-group'
                className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500'
              />
              <div className='text-sm -mt-1'>
                <p>No 1, ABC Street, XYZ Estate, Lagos, Nigeria</p>
                <p>State</p>
                <p>Zip code</p>
                <p>Phone number</p>
                <p>Contact name</p>
              </div>
            </div>

            <div className='flex gap-x-4'>
              <input
                id='inline-radio'
                type='radio'
                value=''
                name='inline-radio-group'
                className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500'
              />
              <div className='text-sm -mt-1'>
                <p>No 1, ABC Street, XYZ Estate, Lagos, Nigeria</p>
                <p>State</p>
                <p>Zip code</p>
                <p>Phone number</p>
                <p>Contact name</p>
              </div>
            </div>

            <div className='flex gap-x-4'>
              <input
                id='inline-radio'
                type='radio'
                value=''
                name='inline-radio-group'
                className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500'
              />
              <div className='text-sm -mt-1'>
                <p>No 1, ABC Street, XYZ Estate, Lagos, Nigeria</p>
                <p>State</p>
                <p>Zip code</p>
                <p>Phone number</p>
                <p>Contact name</p>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </MaxWidthWrapper>
  );
};

export default CheckoutAddress;
