import MaxWidthWrapper from '../../components/MaxWidthWrapper';
import AddressBtn from '../../components/Button';
// import { ORDER_DATA } from '../../data/orderData';
// import {
//   Table,
//   TableBody,
//   TableCaption,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
// } from '../../components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../components/ui/tooltip';
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAddAddressMutation, useAddDefaultAddressMutation, useRemoveAddressMutation } from '../../store/slices/api.slice';
import Loading from '../../components/Loading';
import AlertModal from '../../components/AlertModal';
import { getProfile } from '../../store/slices/auth.slice';
import { useNavigate } from 'react-router-dom';
// import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
const buttonItem = {
  btnTxt: 'Add address',
};

const Addresses = () => {
const [address, setAddress] = useState(''), dispatch = useDispatch();
const [city, setCity] = useState(''), navigate = useNavigate()
const [states, setStates] = useState('');
const [loading, setLoading] = useState(false)
const [showAlert, setShowAlert] = useState({ show: false, msg: '' });
const [closeAddress, setCloseAddress] = useState(false)
const [selectedAddress, setSelectedAddress] =  useState('')
const userAddress = useSelector((state: any) => state.auth.userProfile)


const [addAddress] = useAddAddressMutation()
const [removeAddress] = useRemoveAddressMutation()
const [addDefaultAddress] = useAddDefaultAddressMutation()

useEffect(() => {
  const defaultAddress = userAddress.addresses.find((address: any) => address.default)

  if (defaultAddress) {
    setSelectedAddress(defaultAddress.id);
  }
}, [userAddress])

const handleAddAddress = async (event: any) => {
  event.preventDefault()
    try {
      setLoading(true)
      const body = {address, city, state: states, default: false}

      const responseData = await addAddress(body).unwrap();

      if(responseData.status === true){
        setCloseAddress(false)
        setShowAlert({ show: true, msg: responseData.message });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
        await getProfile(dispatch).catch(err => console.log(err));
      }
      setLoading(false)
    } catch (error: any) {
      setCloseAddress(false)
      console.error(error.data.message)
    }
  }
  const deleteAddress = async(id: string) => {
    try {
      const responseData = await removeAddress(id).unwrap();
      if(responseData.status === true){
        setShowAlert({ show: true, msg: 'Succesfully deleted address' });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
        await getProfile(dispatch).catch(err => console.log(err));
      }
    } catch (error: any) {
        const { message } = error.data
        if(message == 'Error occurred, validation token not found'){
          navigate('/login')
        } 
    }
  }

    const handleOptionChange = async (id: string) => {
      setSelectedAddress(id)
      try {
        const responseData = await addDefaultAddress(id).unwrap();
        if(responseData.status === true){
          setShowAlert({ show: true, msg: 'Succesfully set as default' });
          setTimeout(
            () => setShowAlert((prevState) => ({ ...prevState, show: false })),
            4000
          );
          await getProfile(dispatch).catch(err => console.log(err));
        }
      } catch (error) {
        console.log(error)
      }
    }
  
  

  return (
    <MaxWidthWrapper className='w-full flex justify-between'>
      <div className='w-full flex flex-col mb-12 lg:!mb-0'>
        <div className='order-page-header-wrapper'>
          <h3 className='text-xl lg:text-2xl font-semibold'>
            Addresses
          </h3>
          <Dialog>
            <DialogTrigger  onClick={() => setCloseAddress(true)} className='w-fit checkout-btn'>Add new address</DialogTrigger>
            {closeAddress && <DialogContent>
              <DialogHeader>
                <DialogTitle className='form-heading'>
                  Add new address
                </DialogTitle>
                <form className='w-full flex flex-col space-y-5 mt-5 mb-12 !text-gray-800'>
                  <div className='w-full'>
                    <label htmlFor="address" className='input-label'>Full Address</label>
                    <input
                      type='text'
                      id='address'
                      className='input-box'
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder='20 Street Area'
                    />
                  </div>
                  <div className='w-full'>
                    <label htmlFor="city" className='input-label'>City</label>
                    <input
                      type='text'
                      id='city'
                      className='input-box'
                      placeholder='city'
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className='w-full'>
                    <label htmlFor="state" className='input-label'>State</label>
                    <input
                      type='text'
                      id='state'
                      className='input-box'
                      placeholder='state'
                      value={states}
                      onChange={(e) => setStates(e.target.value)}
                    />
                  </div>
                  {loading ? (
                        <button type="submit" className="checkout-btn">
                          <Loading loading={loading} />
                        </button>
                      ) : (
                        <button type="submit" onClick={handleAddAddress} className="checkout-btn">
                          Add Address
                        </button>)}
                </form>
              </DialogHeader>
            </DialogContent>}
          </Dialog>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
          {userAddress.addresses.map((address: any, index: number) => (
            <div
              key={index}
              className='space-y-7 bg-white p-8 shadow h-fit rounded-xl text-gray-800'
            >
              <h3 className='font-semibold'>Address {index + 1}</h3>
              <p className='text-sm'>{address.address} {address.city} {address.state}</p>
              <div className="flex items-center mt-8">
                <input id="link-radio" type="radio" name="address" value={address.id} checked={selectedAddress === address.id}
        onChange={() => handleOptionChange(address.id)} className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 focus:ring-2" />
                <label htmlFor="link-radio" className="ms-2 text-xs font-semibold text-gray-900">Set as default</label>
              </div>
               <button onClick={() => deleteAddress(address.id)} className='bg-neutral-100 text-red-600 hover:text-red-700 hover:bg-red-200 p-2 rounded-full'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 48 48'
                    >
                      <path
                        fill='currentColor'
                        d='M20 10.5v.5h8v-.5a4 4 0 0 0-8 0m-2.5.5v-.5a6.5 6.5 0 1 1 13 0v.5h11.25a1.25 1.25 0 1 1 0 2.5h-2.917l-2 23.856A7.25 7.25 0 0 1 29.608 44H18.392a7.25 7.25 0 0 1-7.224-6.644l-2-23.856H6.25a1.25 1.25 0 1 1 0-2.5zm-3.841 26.147a4.75 4.75 0 0 0 4.733 4.353h11.216a4.75 4.75 0 0 0 4.734-4.353L36.324 13.5H11.676zM21.5 20.25a1.25 1.25 0 1 0-2.5 0v14.5a1.25 1.25 0 1 0 2.5 0zM27.75 19c.69 0 1.25.56 1.25 1.25v14.5a1.25 1.25 0 1 1-2.5 0v-14.5c0-.69.56-1.25 1.25-1.25'
                      />
                    </svg>
                    </button>
            </div>
          ))}
        </div>
      </div>
      {showAlert.show && (
          <AlertModal
            msg={showAlert.msg}
            click={() =>
              setShowAlert((prevState) => ({ ...prevState, show: false }))
            }
          />
        )}
    </MaxWidthWrapper>
  );
};

export default Addresses;
