import React, { useState, useRef } from 'react';
import productImage2 from '../images/default_image.png';
import productImage3 from '../images/default_image.png';
import productImage4 from '../images/default_image.png';

const ProductSlider: React.FC<any> = ({productImage, isSuccess}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const productImages = [productImage2, productImage3, productImage4];
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const goToPreviousSlide = () => {
    if(isSuccess){
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? productImage.length - 1 : prevIndex - 1
      );
    } else {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? productImages.length - 1 : prevIndex - 1
      );
    }
  };

  const goToNextSlide = () => {
    if(isSuccess){
      setCurrentImageIndex((prevIndex) =>
        prevIndex === productImage.length - 1 ? 0 : prevIndex + 1
      );
    } else {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === productImages.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartX.current = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    touchEndX.current = e.targetTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      goToNextSlide();
    }

    if (touchStartX.current - touchEndX.current < -50) {
      goToPreviousSlide();
    }
  };

  const openLightbox = () => {
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  return (
    <div className='mb-14 md:mb-24 product-slider md:mt-4'>
      <div className='slider-container flex items-center justify-center gap-x-4 w-full md:w-full lg:w-[100%] xl:w-[400px]'>
        <button className='slider-btn prev' onClick={goToPreviousSlide}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1.5em'
            height='1.5em'
            viewBox='0 0 24 24'
          >
            <path
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m15 18l-6-6l6-6'
            />
          </svg>
        </button>
        <div
          className='slider-image w-full h-[50vh] lg:h-[35vh] xl:w-[90%] xl:h-[250px] flex flex-col justify-center overflow-hidden'
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <img
            src={ isSuccess ? (productImage.length > 0 ? productImage[currentImageIndex].url : productImages[currentImageIndex]) :  productImages[currentImageIndex]}
            className='object-contain w-full h-full cursor-pointer'
            alt={`Product ${currentImageIndex + 1}`}
            onClick={openLightbox}
            width={400}
            height={400}
          />
        </div>
        <button className='slider-btn next' onClick={goToNextSlide}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='rotate-180'
            width='1.5em'
            height='1.5em'
            viewBox='0 0 24 24'
          >
            <path
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m15 18l-6-6l6-6'
            />
          </svg>
        </button>
      </div>
      <div className='flex items-center justify-center mb-2 md:mt-8 slider-dots xl:mt-5 gap-x-4'>
        {isSuccess ? (<>
        {productImage.length > 0 ?  (
          <>
          {productImage.map((_:any, index: any) => (
          <span
            key={index}
            className={`slider-dot w-2 h-2 bg-neutral-400 rounded-full ${
              index === currentImageIndex
                ? 'active !bg-green-500'
                : 'bg-green-500'
            }`}
            onClick={() => setCurrentImageIndex(index)}
          ></span> 
        ))}
        </>
        )
          : (
            <>
            {productImages.map((_:any, index: any) => (
            <span
              key={index}
              className={`slider-dot w-2 h-2 bg-neutral-400 rounded-full ${
                index === currentImageIndex
                  ? 'active !bg-green-500'
                  : 'bg-green-500'
              }`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
          </>
          )
        }
        </>) :
        (
          <>
          {productImages.map((_:any, index: any) => (
          <span
            key={index}
            className={`slider-dot w-2 h-2 bg-neutral-400 rounded-full ${
              index === currentImageIndex
                ? 'active !bg-green-500'
                : 'bg-green-500'
            }`}
            onClick={() => setCurrentImageIndex(index)}
          ></span>
        ))}
        </>
        )
        }
      </div>

      {/* Lightbox Modal */}
      {isLightboxOpen && (
        <div
          className='fixed inset-0 z-50 flex items-center justify-center bg-black lightbox bg-opacity-80'
          onClick={closeLightbox}
        >
          <div className='relative lightbox-content'>
            <img
              src={ isSuccess ? (productImage.length > 0 ? productImage[currentImageIndex].url : productImages[currentImageIndex]) :  productImages[currentImageIndex]}
              className='object-contain w-full h-full'
              alt={`Product ${currentImageIndex + 1}`}
            />
            <button
              className='absolute text-3xl text-white top-4 right-4'
              onClick={closeLightbox}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductSlider;
