import apiInstance from './axios.service';

const AccountService = {
  addAddress() {
    return apiInstance({
      method: 'post',
    //   url: '',
    });
  },
// Get Coupons
  getCoupons(){
    return apiInstance({
      method: 'get',
      url: 'inventory-service/coupons'
    })
  },
// Add Wish list or saved items
  addWishList(sku: string){
    return apiInstance({
      method: 'post',
      url: 'inventory-service/wishlist',
      data: {
        sku: sku
      }
    });
  },
// Get Wish list or saved items
  getWishList(){
    return apiInstance({
      method: 'get',
      url: 'inventory-service/wishlist'
    })
  },
  // Remove Wish list or saved items
  removeWishList(item: string){
    return apiInstance({
      method: 'delete',
      url: `inventory-service/wishlist/${item}`
    })
  },

  getOrders(){
    return apiInstance({
      method: 'get',
      url: 'payment-service/orders'
    })
  },

  getProfile(){
    return apiInstance({
      method: 'get',
      url: 'users-service/users/profile'
    })
    
  },

  updateName(data: any){
    return apiInstance({
      method: 'patch',
      url: 'users-service/users/profile/update',
      data
    });
  },

  updatePasword(data: any){
    return apiInstance({
      method: 'patch',
      url: 'users-service/users/profile/change-password',
      data
    });
  }

}

export default AccountService;