import React, { ChangeEvent } from 'react';
import cn from '../lib/utils';

interface QuantityControlProps {
  quantity: number;
  onIncrement: (value: number) => void;
  onDecrement?: () => void;
  className?: string;
}

const QuantityControl: React.FC<QuantityControlProps> = ({ quantity, onIncrement, onDecrement, className }) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10) || 1;
    onIncrement(newValue);
  };

  return (
    <div 
      className={cn('w-full h-fit flex items-center justify-between gap-x-6 px-1 p text-sm font-medium text-gray-900 rounded-full group bg-transparent border-2 border-gray-700',
        className
      )}
    >
      <button 
        type='button'
        id='decrement-button'
        onClick={onDecrement}
        className='plus-minus-btn'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 48 48'
        >
          <path
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='4'
            d='M10.5 24h28'
          />
        </svg>
      </button>
      <input 
        type='number'
        value={quantity}
        onChange={handleInputChange}
        className='w-full text-center focus:outline-0 py-2 font-lora text-base text-gray-700 rounded-lg !bg-tkzBg' 
      />
      <button 
        type='button'
        id='increment-button'
        onClick={() => onIncrement(quantity + 1)}
        className='plus-minus-btn'
      > <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path
        fill='currentColor'
        d='M12.75 11.25V5a.75.75 0 0 0-1.5 0v6.25H5a.75.75 0 0 0 0 1.5h6.25V19a.76.76 0 0 0 .75.75a.75.75 0 0 0 .75-.75v-6.25H19a.75.75 0 0 0 .75-.75a.76.76 0 0 0-.75-.75Z'
      />
    </svg>
       
      </button>
    </div>
  );
};

export default QuantityControl;