import React, { ReactNode } from 'react';

interface MaxWidthWrapperProps {
  children: ReactNode;
  className?: string;
}

export default function MaxWidthWrapper({
  children,
  className,
}: MaxWidthWrapperProps) {
  return (
    <div
      className={
        className ? `max-width-wrapper ${className}` : 'max-width-wrapper'
      }
    >
      {children}
    </div>
  );
}
