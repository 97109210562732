const PriceFormatter = (price: number, includeCurrencySymbol = true) => {
    const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
        minimumFractionDigits: 0,
    }).format(price);

    if (!includeCurrencySymbol) {
        return formattedPrice.replace('NGN', '').trim();
    }

    return formattedPrice;
};

export default PriceFormatter;
