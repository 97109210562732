import { Link } from 'react-router-dom';
import categoryPhoto from 'src/images/pages/customer_support.png';
import SendBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import Breadcrumb from './../components/Breadcrumb';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'src/components/ui/tabs';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';

const CustomerSupport = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Customer support', to: '' },
  ];

  const FAQs = [
    {
      title: 'FAQ 1',
      content: 'FAQ 1 contents',
    },
    {
      title: 'FAQ 2',
      content: 'FAQ 2 contents',
    },
    {
      title: 'FAQ 3',
      content: 'FAQ 3 contents',
    },
    {
      title: 'FAQ 4',
      content: 'FAQ 4 contents',
    },
    {
      title: 'FAQ 5',
      content: 'FAQ 5 contents',
    },
  ];

  const buttonItem = {
    link: '/',
    btnTxt: 'Send',
  };

  return (
    <div className='mb-4 md:mb-16'>
      <MaxWidthWrapper className='pb-6 bg-neutral-100 lg:pb-8'>
        <Breadcrumb items={breadcrumbItems} />
        <div className='relative flex items-center justify-center w-full rounded-lg md:rounded-xl'>
          <span className='absolute font-semibold text-white md:text-xl lg:text-3xl xl:text-4xl whitespace-nowrap'>
            Customer Support
          </span>
          <img
            src={categoryPhoto}
            alt='Taikez customer support agent on a call with a user'
            width={1000}
            height={1000}
            className='object-cover w-full h-[110px] md:h-full rounded-lg md:rounded-xl'
          />
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='xl:max-w-[80%] pt-4 md:pt-8 lg:pt-6 xl:pt-4 lg:min-h-[60vh]'>
        <Tabs defaultValue='faq' className='flex justify-center w-full'>
          <div className='w-full md:w-[90%] lg:w-[60%] flex flex-col justify-center items-center'>
            <TabsList className='w-full space-x-14 mb-4 md:mb-6 !bg-transparent'>
              <TabsTrigger value='faq'>FAQs</TabsTrigger>
              <TabsTrigger value='contact'>Contact Us</TabsTrigger>
            </TabsList>
            <TabsContent value='faq' className='w-full'>
              <Accordion type='single' collapsible>
                {FAQs.map((faq, index) => (
                  <AccordionItem
                    value={`item-${index}`}
                    key={index}
                    className='border-b-[1.5px] border-gray-700'
                  >
                    <AccordionTrigger className='text-sm'>
                      {faq.title}
                    </AccordionTrigger>
                    <AccordionContent>{faq.content}</AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </TabsContent>
            <TabsContent value='contact' className='w-full'>
              <div className='flex flex-col items-center w-full mt-4 border-gray-700 lg:px-4 lg:py-7 lg:border-2 rounded-3xl'>
                <form className='space-y-8'>
                  <input
                    type='text'
                    id='fullName'
                    className='input-box'
                    placeholder='Full name'
                  />
                  <input
                    type='text'
                    id='email'
                    className='input-box'
                    placeholder='Email'
                  />
                  <input
                    type='number'
                    id='phoneNo'
                    className='input-box'
                    placeholder='Phone number'
                  />
                  <textarea
                    id='message'
                    rows={4}
                    className='textarea-box'
                    placeholder='Message'
                  ></textarea>
                </form>
                <div className='w-full lg:w-[30%] mx-auto mt-4 mb-5'>
                  <SendBtn item={buttonItem} />
                </div>
              </div>
              <div className='flex gap-y-3 justify-between !items-start all-small-txt text-gray-700 mt-3 px-3'>
                <div className='flex items-center w-full gap-x-3'>
                  <Link
                    to='https://facebook.com/share/LVJab%E2%80%A6'
                    className='hover:text-green-700'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 1000 1000'
                    >
                      <path
                        fill='currentColor'
                        d='M182.594 0C81.445 0 0 81.445 0 182.594v634.813c0 101.149 81.445 182.594 182.594 182.594h344.063V609.063H423.282v-140.75h103.375v-120.25c0-94.475 61.079-181.219 201.781-181.219c56.968 0 99.094 5.469 99.094 5.469l-3.313 131.438s-42.963-.406-89.844-.406c-50.739 0-58.875 23.378-58.875 62.188v102.781h152.75l-6.656 140.75H675.5v390.938h141.906c101.149 0 182.594-81.445 182.594-182.594V182.595C1000 81.446 918.555.001 817.406.001H182.593z'
                      />
                    </svg>
                  </Link>
                  <Link
                    to='https://www.instagram.com/taikez_food?igsh=MTVpZ2w2dXdmaml4Ng=='
                    className='hover:text-green-700'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='23'
                      height='23'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill='currentColor'
                        d='M8 5.67C6.71 5.67 5.67 6.72 5.67 8S6.72 10.33 8 10.33S10.33 9.28 10.33 8S9.28 5.67 8 5.67M15 8c0-.97 0-1.92-.05-2.89c-.05-1.12-.31-2.12-1.13-2.93c-.82-.82-1.81-1.08-2.93-1.13C9.92 1 8.97 1 8 1s-1.92 0-2.89.05c-1.12.05-2.12.31-2.93 1.13C1.36 3 1.1 3.99 1.05 5.11C1 6.08 1 7.03 1 8s0 1.92.05 2.89c.05 1.12.31 2.12 1.13 2.93c.82.82 1.81 1.08 2.93 1.13C6.08 15 7.03 15 8 15s1.92 0 2.89-.05c1.12-.05 2.12-.31 2.93-1.13c.82-.82 1.08-1.81 1.13-2.93c.06-.96.05-1.92.05-2.89m-7 3.59c-1.99 0-3.59-1.6-3.59-3.59S6.01 4.41 8 4.41s3.59 1.6 3.59 3.59s-1.6 3.59-3.59 3.59m3.74-6.49c-.46 0-.84-.37-.84-.84s.37-.84.84-.84s.84.37.84.84a.8.8 0 0 1-.24.59a.8.8 0 0 1-.59.24Z'
                      />
                    </svg>
                  </Link>
                </div>
                <div className='w-[90%] flex flex-col'>
                  <div className='flex flex-col md:space-x-2 md:flex-row'>
                    <Link
                      className='text-green-600 hover:text-green-700 w-fit'
                      to='tel:+234 901 907 7479'
                    >
                      +234 901 907 7479
                    </Link>
                    <Link
                      className='text-green-600 hover:text-green-700 w-fit'
                      to='tel:+234 905 344 0496'
                    >
                      +234 905 344 0496
                    </Link>
                  </div>
                  <address>
                    21, Togedejoye Street, Ojokoro Village, Agric Ikorodu, Lagos
                    State, Nigeria. 102222
                  </address>
                </div>
              </div>
            </TabsContent>
          </div>
        </Tabs>
      </MaxWidthWrapper>
    </div>
  );
};

export default CustomerSupport;
