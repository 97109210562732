import React, { useState, useEffect, useRef } from 'react';
import PriceFormatter from '../config/formatters/priceFormatter';
import _ from 'lodash';
import taikezProductImage from '../images/default_image.png';
import ProductSkeleton from './ProductSkeleton';
import CartService from '../config/services/cart.service';
import { Link } from 'react-router-dom';
import CartBtn from './CartBtn';

const ProductGridSection: React.FC<any> = ({ productData, loading, isSuccess}) => {
  const scrollRef = useRef(0);
  
  useEffect(() => {
    window.scrollTo(0, scrollRef.current);
  }, []);

  useEffect(() => {
    return () => {
      scrollRef.current = window.scrollY;
    };
  }, []);
  
  return (
    <section className='flex flex-col w-full'>
      {Object.keys(productData).map((cpKey) => {
        const category = productData[cpKey];

        // Shuffle the products array
        const products = category.products;
        const shuffledProducts = _.shuffle(products);

        // Select 4 random products
        const randomProducts = shuffledProducts.slice(0, 4);

        return (
          <div key={category.id}>
            <div className='flex justify-between items-center text-gray-900 mb-4 py-1.5'>
              <h3 className='text-xl font-bold'>{category.name}</h3>
              <Link
                to={`/category/${category.slug}`}
                className='flex items-center justify-between px-4 py-1 text-xs font-medium text-gray-800 border-2 border-gray-900 rounded-full md:text-sm hover:bg-green-500 hover:text-white hover:border-green-500'
              >
                See more
              </Link>
            </div>
            {loading || randomProducts.length === 0 ? (
              <div className='grid w-full grid-cols-2 mb-24 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-4 lg:gap-x-8 xl:gap-x-4 gap-y-12 xl:gap-y-14'>
                <ProductSkeleton />
                <ProductSkeleton />
                <ProductSkeleton />
                <ProductSkeleton />
              </div>
            ) : (
              <div className='grid w-full grid-cols-2 mb-24 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-4 lg:gap-x-8 xl:gap-x-4 gap-y-12 xl:gap-y-14'>
                {randomProducts.map((product: any) => {
                  return (
                    <div
                      key={product.id}
                      className='relative flex flex-col flex-wrap w-full mx-auto bg-white shadow-sm rounded-3xl'
                    >
                      {product.stock > 0 ? (
                        <Link
                          to={`/product/${product.sku}`}
                          key={product.id}
                          className='w-full overflow-hidden rounded-3xl'
                        >
                          <img
                            src={isSuccess ? (product.image.length > 0 ? product.image[0].url : taikezProductImage) : taikezProductImage}
                            alt={product.name}
                            width={200}
                            height={200}
                            className='object-cover text-xs w-full md:h-[15rem] lg:h-[16rem] xl:h-[10rem] p-1 md:p-2 rounded-3xl mb-2 shadow product-animate'
                          />
                        </Link>
                      ) : (
                        <div className='relative w-full overflow-hidden cursor-not-allowed rounded-3xl'>
                          <img
                            src={isSuccess ? (product.image.length > 0 ? product.image[0].url : taikezProductImage) : taikezProductImage}
                            alt={product.name}
                            width={200}
                            height={200}
                            className='object-cover text-xs w-full md:h-[15rem] lg:h-[16rem] xl:h-[10rem] p-1 md:p-2 rounded-3xl mb-2 shadow product-animate opacity-50'
                          />
                          <div className='absolute inset-0 flex items-center justify-center text-lg font-bold text-white bg-black bg-opacity-50'>
                            Out of Stock
                          </div>
                        </div>
                      )}
                      <div className='product-name-price'>
                        <p
                          className={
                            product.stock > 0 ? 'product-name' : 'out-of-stock'
                          }
                        >
                          {product.name}
                        </p>
                        <p
                          className={
                            product.stock > 0 ? 'product-price' : 'out-of-stock'
                          }
                        >
                          ₦ {PriceFormatter(product.price, false)}
                        </p>

                      </div>
                      <CartBtn productSku={product.sku} price={product.price} name={product.name} stock={product.stock} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ProductGridSection;
