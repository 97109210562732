import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MaxWidthWrapper from '../../components/MaxWidthWrapper';
// import { SAVED_ITEMS_DATA } from '../../data/savedItemsData';
import taikezProductImage from '../../images/default_image.png';
import AccountService from '../../config/services/account.service';
import PriceFormatter from '../../config/formatters/priceFormatter';
import {
  useGetWishListQuery,
  useRemoveWishlistMutation,
} from '../../store/slices/api.slice';
import CartBtn from '../../components/CartBtn';
// import {
//   Table,
//   TableBody,
//   TableCaption,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
// } from "../../components/ui/table";

const OrderItems = () => {
  const { data: savedItems, refetch, isSuccess } = useGetWishListQuery('');
  const [removeWishList] = useRemoveWishlistMutation();
  const navigate = useNavigate();

  const removeSavedItems = async (sku: string) => {
    try {
      await removeWishList(sku);
      refetch();
    } catch (error: any) {
      const { message } = error
      if(message == 'Error occurred, validation token not found'){
        navigate('/login')
      } else{
        navigate('/login')

      }
    }
  };

  return (
    <MaxWidthWrapper>
      <div className='flex-col order-page-header-wrapper'>
        <h3 className='text-base lg:text-2xl font-semibold mt-3 whitespace-nowrap !mb-5'>
          Saved Items
        </h3>
        <>
          {isSuccess ? (
            <>
              {savedItems.data.length > 0 ? (
                savedItems.data.map((item: any, index: any) => (
                  <div
                    key={index}
                    className='w-full lg:w-full xl:w-[80%] flex px-4 lg:px-8 py-3 mb-4 bg-white rounded-md shadow text-xs lg:text-base !text-left justify-between'
                  >
                    <Link
                      to={`/product/${item?.sku}`}
                      className='flex w-full gap-x-5'
                    >
                      <img
                        src={taikezProductImage}
                        alt='image'
                        width={1000}
                        height={1000}
                        className='object-cover w-[60px] md:w-[90px] h-[60px] md:h-[90px] border rounded-full'
                      />
                      <div className='pt-2 text-left'>
                        <p className='font-semibold'>
                          {item?.name} <span>{item?.size}</span>
                        </p>
                        <p className='text-sm font-medium'>
                          ₦ {PriceFormatter(item.price, false)}
                        </p>
                      </div>
                    </Link>
                    {/* <div className='w-[45%] text-left pt-2 ms-8'>
                      <p className='category-btn'>{item?.category_name}</p>
                    </div> */}
                    <div className='w-[30%] pt-2 space-y-4 flex flex-col items-end'>
                      {/* <button
                        onClick={() => removeSavedItems(item.sku)}
                        className='!bg-green-500 hover:!bg-green-600 !text-white !py-2 outline-btn'
                      >
                        Add to cart
                      </button> */}
                      {/* <CartBtn productSku={product.sku} stock={product.stock} /> */}

                      <button
                        onClick={() => removeSavedItems(item.sku)}
                        className='hover:!bg-red-100 !py-2 outline-btn'
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Saved Items</div>
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </>
      </div>
    </MaxWidthWrapper>
  );
};

export default OrderItems;
