import React from 'react'
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  items: { label: string; to: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => (
  <nav
    className='flex justify-center lg:justify-start items-center pt-3 mb-5 md:mb-3 lg:mb-6'
    aria-label='Breadcrumb'
  >
    <ol className='inline-flex items-center gap-x-1 md:gap-x-2 rtl:gap-x-reverse'>
      {items.map((item, index) => (
        <li key={index} className='flex items-center'>
          {index > 0 && (
            <div className='flex items-center'>
              <svg
                className='rtl:rotate-180 w-2 h-1.5 text-gray-500 mx-1'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 6 10'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                  d='m1 9 4-4-4-4'
                />
              </svg>
              {index === items.length - 1 ? (
                <span className='breadcrumbTxt'>{item.label}</span>
              ) : (
                <Link
                  to={item.to}
                  className='breadcrumbTxt hover:font-green-800'
                >
                  {item.label}
                </Link>
              )}
            </div>
          )}
          {index === 0 && (
            <Link to={item.to} className='inline-flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 48 48'
              >
                <g fill='currentColor'>
                  <path d='M22.5 28a1.5 1.5 0 1 1 0-3a1.5 1.5 0 0 1 0 3' />
                  <path
                    fillRule='evenodd'
                    d='M9.429 20c.194 0 .385-.016.571-.048V28H7.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h33a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H38v-8.048c.186.032.377.048.571.048C40.473 20 42 18.448 42 16.551v-2.39a2.73 2.73 0 0 0-.163-.93l-2.18-6.01A1.85 1.85 0 0 0 37.917 6H10.082a1.85 1.85 0 0 0-1.738 1.222l-2.18 6.009a2.728 2.728 0 0 0-.164.93v2.39C6 18.448 7.527 20 9.429 20m-1.385-6.087L10.189 8H37.81l2.145 5.913c.03.08.044.163.044.248v2.39A1.44 1.44 0 0 1 38.57 18c-.78 0-1.428-.64-1.428-1.449a1 1 0 0 0-2 0A1.44 1.44 0 0 1 33.713 18c-.78 0-1.428-.64-1.428-1.448a1 1 0 0 0-2 0c0 .808-.648 1.448-1.429 1.448s-1.428-.64-1.428-1.449a1 1 0 1 0-2 0A1.439 1.439 0 0 1 24 18a1.44 1.44 0 0 1-1.429-1.448a1 1 0 0 0-2 0c0 .808-.647 1.448-1.428 1.448c-.781 0-1.429-.64-1.429-1.449a1 1 0 1 0-2 0c0 .808-.647 1.449-1.428 1.449c-.781 0-1.429-.64-1.429-1.449a1 1 0 1 0-2 0c0 .808-.647 1.449-1.428 1.449C8.647 18 8 17.36 8 16.551v-2.39a.72.72 0 0 1 .044-.248M36 19.122a3.401 3.401 0 0 1-2.286.878a3.404 3.404 0 0 1-2.428-1.014A3.404 3.404 0 0 1 28.857 20a3.404 3.404 0 0 1-2.428-1.014A3.404 3.404 0 0 1 24 20a3.404 3.404 0 0 1-2.429-1.014A3.404 3.404 0 0 1 19.143 20c-.951 0-1.81-.389-2.429-1.014A3.404 3.404 0 0 1 14.286 20c-.88 0-1.68-.333-2.286-.878V28h2.5a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v.5h1.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H36zM8 33a1 1 0 0 1 1-1h30a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm2 7v-6h28v6z'
                    clipRule='evenodd'
                  />
                </g>
              </svg>
              <span className='breadcrumbTxt hover:font-green-800'>
                {item.label}
              </span>
            </Link>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

export default Breadcrumb;
