export const SLIDER_DATA = [
  {
    id: 1,
    link: '/',
    name: 'fruits',
    imageSrc: require('../images/slider_banners/fruits.webp'),
  },
  {
    id: 2,
    link: '/',
    name: 'more fruits',
    imageSrc: require('../images/slider_banners/fruits3.png'),
  },
  {
    id: 3,
    link: '/',
    name: 'pepper and tomatoes',
    imageSrc: require('../images/slider_banners/pepper_and_tomatoes.png'),
  },
  {
    id: 4,
    link: '/',
    name: 'hero image',
    imageSrc: require('../images/slider_banners/hero.png'),
  },
];
