import React, { MouseEvent, useEffect, useState } from 'react';
import MaxWidthWrapper from './MaxWidthWrapper';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from './ui/navigation-menu';
// categories
import vegetablesSVG from 'src/images/cate_icons/vegetables.png';
import beveragesSVG from 'src/images/cate_icons/beverages.png';
import floursSVG from 'src/images/cate_icons/flours.png';
import fruitsSVG from 'src/images/cate_icons/fruits.png';
import grainsSVG from 'src/images/cate_icons/grains.png';
import groceriesSVG from 'src/images/cate_icons/groceries.png';
import meatsSVG from 'src/images/cate_icons/meats.png';
import nutsSVG from 'src/images/cate_icons/nuts.png';

import blackLogo from 'src/images/logos/black_logo.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TokenHelper from '../config/utils/token.helper';
import {
  useGetCartsQuery,
  useGetCategoriesQuery,
} from '../store/slices/api.slice';
import { getProfile, removeAuthentication } from '../store/slices/auth.slice';
import CookieHelper from '../config/utils/cookie.helper';
import { AppKeys } from '../config/utils/constants';

// interface Category {
//   id: string;
//   name: string;
//   slug: string;
// }

const Header = () => {
  // category svgs
  const categorySVGs: { [key: string]: string } = {
    Vegetables: vegetablesSVG,
    Beverages: beveragesSVG,
    Flours: floursSVG,
    Fruits: fruitsSVG,
    Grains: grainsSVG,
    Groceries: groceriesSVG,
    Meats: meatsSVG,
    Nuts: nutsSVG,
  };
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const isLoggedIn: boolean = TokenHelper.authenticationValid();
  const [isProfile, setIsProfile] = useState(false);
  const [inputValue, setInputValue] = useState<any>('');
  const { data: categories, isLoading, isSuccess } = useGetCategoriesQuery('');
  const userProfile: IProfile = useSelector(
    (state: any) => state.auth.userProfile
  );
  const { data: cartItem, isSuccess: isSucessCarts } = useGetCartsQuery('');
  const cartCookie = CookieHelper.get(AppKeys.CART) || [];
  const toggleMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  console.log(cartCookie);

  useEffect(() => {
    setIsProfile(isLoggedIn);
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const scrollThreshold = 0;

      setIsSticky(scrollPosition > scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Search Products
  const handleSearch = () => {
    if (inputValue.trim()) {
      navigate(`/allproducts/search/${inputValue}`);
    }
  };

  // Logout
  const handleLogout = async (event: MouseEvent) => {
    event.preventDefault();
    await removeAuthentication();
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <MaxWidthWrapper className='!px-0 '>
      <header className='w-full text-gray-800'>
        {/* mobile upper header */}
        <div className='z-20 flex flex-col items-center w-full border-b bg-tkzBg lg:hidden border-neutral-300'>
          {/* mobile upper header */}
          <Link
            to='/'
            onClick={() => setMobileMenuOpen(false)}
            className='my-4'
          >
            <img
              src={blackLogo}
              alt='Taikez ecommerce logo'
              width={300}
              height={300}
              className='w-[7rem]'
            />
          </Link>
          {/* mobile lower header */}
          <div
            className={`${
              isSticky
                ? 'fixed top-0 mobile-header shadow z-10 py-2'
                : 'mobile-header pb-6'
            }`}
          >
            {/* mobile left section */}

            <div
              className={`menu-icon ${isMobileMenuOpen ? 'open' : ''}`}
              onClick={toggleMenu}
            >
              <div className='bar'></div>
              <div className='bar'></div>
              <div className='bar'></div>
            </div>

            {/* mobile center section */}
            <div className='w-full ps-3 border-[2px] lg:border-[1.5px] border-gray-700 rounded-full'>
              <form>
                <div className='flex items-center justify-between'>
                  <input
                    type='search'
                    id='search-dropdown'
                    className='block p-2 w-full text-sm font-medium !bg-transparent text-gray-900 rounded-e-full focus:!outline-0 placeholder:text-gray-500 placeholder:font-normal'
                    placeholder='Search for products...'
                    required
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <button
                    type='submit'
                    onClick={handleSearch}
                    className='p-3 rounded-full bg-neutral-300 hover:bg-stone-400'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      strokeWidth={2}
                    >
                      <path
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314Z'
                      />
                    </svg>
                  </button>
                  <span onClick={handleSearch} className='hidden '>
                    Search
                  </span>
                </div>
              </form>
            </div>

            {/* mobile right section */}
            <Link
              to='/cart'
              onClick={() => setMobileMenuOpen(false)}
              className='relative flex items-center text-sm font-medium'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='45'
                height='45'
                viewBox='0 0 2048 1792'
                className='text-white p-[10px] rounded-full bg-green-600 hover:bg-green-700 shadow'
              >
                <path
                  fill='currentColor'
                  d='M1920 768q53 0 90.5 37.5T2048 896t-37.5 90.5t-90.5 37.5h-15l-115 662q-8 46-44 76t-82 30H384q-46 0-82-30t-44-76l-115-662h-15q-53 0-90.5-37.5T0 896t37.5-90.5T128 768zM485 1568q26-2 43.5-22.5T544 1499l-32-416q-2-26-22.5-43.5T443 1024t-43.5 22.5T384 1093l32 416q2 25 20.5 42t43.5 17zm411-64v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m384 0v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m352 5l32-416q2-26-15.5-46.5T1605 1024t-46.5 15.5t-22.5 43.5l-32 416q-2 26 15.5 46.5t43.5 22.5h5q25 0 43.5-17t20.5-42M476 292l-93 412H251l101-441q19-88 89-143.5T601 64h167q0-26 19-45t45-19h384q26 0 45 19t19 45h167q90 0 160 55.5t89 143.5l101 441h-132l-93-412q-11-44-45.5-72t-79.5-28h-167q0 26-19 45t-45 19H832q-26 0-45-19t-19-45H601q-45 0-79.5 28T476 292'
                />
              </svg>
              <span className='absolute top-[1px] -right-2 w-6 h-6 text-gray-700 text-sm text-center font-semibold rounded-full bg-white shadow'>
                {isSucessCarts ? cartItem.data.length : cartCookie.length}
              </span>
            </Link>
          </div>
        </div>

        {/* upper header  */}
        <div className='upper-header'>
          <NavigationMenu>
            <NavigationMenuList className='hover:text-gray-900'>
              <NavigationMenuItem>
                <NavigationMenuTrigger className='!bg-inherit hover:!bg-inherit !text-black hover:!text-gray-900 px-0'>
                  Get Help
                </NavigationMenuTrigger>
                <NavigationMenuContent className='!w-[200px] !h-fit p-2 space-y-2 bg-white'>
                  <Link to='customer-support' className='cat-link'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 50 50'
                    >
                      <path
                        fill='currentColor'
                        d='M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17s-7.6 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15s15-6.7 15-15s-6.7-15-15-15z'
                      />
                      <path
                        fill='currentColor'
                        d='M19.8 19.6c.3-.8.6-1.4 1.2-1.9c.5-.5 1.1-.9 1.9-1.2s1.6-.4 2.5-.4c.7 0 1.4.1 2 .3c.6.2 1.2.5 1.7.9s.9.9 1.1 1.5c.3.6.4 1.3.4 2c0 1-.2 1.8-.6 2.5s-1 1.3-1.6 2l-1.3 1.3c-.3.3-.6.6-.7.9c-.2.3-.3.7-.3 1.1c-.1.4-.1.7-.1 1.5h-1.6c0-.8 0-1.1.1-1.7c.1-.5.3-1 .5-1.5c.2-.4.5-.8.9-1.2c.4-.4.9-.8 1.4-1.4c.5-.5.9-1 1.2-1.5s.5-1.2.5-1.8c0-.5-.1-1-.3-1.4c-.2-.4-.5-.8-.8-1.1c-.3-.3-.7-.5-1.2-.7c-.5-.2-.9-.3-1.4-.3c-.7 0-1.3.1-1.8.4c-.5.2-1 .6-1.3 1c-.3.4-.6.9-.8 1.5s-.4.9-.4 1.6h-1.6c0-.9.1-1.6.4-2.4zM26 32v2h-2v-2h2z'
                      />
                    </svg>
                    FAQs
                  </Link>
                  <Link to='about-us' className='cat-link'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                    >
                      <path
                        fill='currentColor'
                        d='M14.98 19.5v-4.485q0-.552.36-.957q.36-.406.912-.512q1.617-.263 2.673-1.443q1.056-1.18 1.056-2.795q0-1.766-1.271-3.037Q17.438 5 15.673 5q-1.615 0-2.795 1.065q-1.18 1.066-1.443 2.664q-.106.552-.512.911q-.406.36-.958.36H4.921l1.746 1.746q.146.146.153.344q.007.198-.158.364q-.166.16-.354.162q-.189.003-.354-.162l-2.389-2.389q-.13-.13-.183-.267T3.329 9.5q0-.162.053-.298q.053-.137.183-.267l2.389-2.389q.14-.14.341-.153q.201-.012.367.153q.16.16.16.354t-.16.354L4.922 9h5.076q.154 0 .27-.115q.115-.116.153-.27q.33-1.957 1.782-3.286Q13.653 4 15.673 4q2.212 0 3.76 1.548q1.548 1.548 1.548 3.76q0 2.019-1.339 3.46q-1.338 1.442-3.277 1.772q-.153.039-.269.164q-.115.125-.115.279V19.5q0 .213-.143.357q-.144.143-.357.143q-.214 0-.357-.143q-.143-.144-.143-.357Z'
                      />
                    </svg>
                    About us
                  </Link>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <div className='flex items-center text-sm font-medium text-black whitespace-nowrap gap-x-1 hover:text-gray-900'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              className='w-3.5 h-3.5'
            >
              <path
                fillRule='evenodd'
                d='M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z'
                clipRule='evenodd'
              />
            </svg>
            <Link to='tel:+234 901 907 7479'>0901 907 7479</Link>
          </div>
        </div>

        {/* lower header */}
        <div
          className={`${
            isSticky ? 'fixed top-0 lower-header shadow z-10' : 'lower-header'
          }`}
        >
          {/* left section */}
          <Link
            to='/'
            onClick={() => setMobileMenuOpen(false)}
            className='w-fit'
          >
            <img
              src={blackLogo}
              alt='Taikez ecommerce logo'
              width={300}
              height={300}
              className='w-full'
            />
          </Link>

          {/* center section */}
          <div className='w-[60%] xl:w-[50%] me-6 rounded-full flex gap-x-2'>
            <NavigationMenu>
              <NavigationMenuList className='border-2 border-gray-700 rounded-full'>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className='!bg-inherit h-fit hover:!bg-inherit hover:!text-green-600 gap-x-2 lg:gap-x-1 !px-3 lg:!ps-3 xl:!gap-x-3 !z-0'>
                    Categories
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className='!w-[200px] !h-fit py-2 space-y-2 bg-white'>
                    {isSuccess ? (
                      categories.data.map((category: any) => (
                        <div key={category.id}>
                          <Link
                            to={`/category/${category.slug}`}
                            className='cat-link'
                          >
                            <img
                              src={categorySVGs[category.name]}
                              alt={category.name}
                              width={25}
                              height={25}
                            />
                            {category.name}
                          </Link>
                        </div>
                      ))
                    ) : (
                      <span className='text-xs'>Loading...</span>
                    )}
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
            <div
              id='dropdown'
              className='z-10 hidden bg-white divide-y divide-gray-100 rounded-full shadow w-44 dark:bg-gray-700'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='currentColor'
                className='w-4 h-4'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                />
              </svg>
            </div>
            <div className='flex items-center w-full gap-x-3'>
              <input
                type='search'
                id='search-dropdown'
                className='px-2.5 lg:px-3 py-2 w-[75%] z-20 text-sm font-medium text-gray-900 !bg-transparent rounded-full border-2 border-gray-700 focus:border-gray-900 focus:!outline-0 placeholder:text-gray-500 placeholder:font-normal md:placeholder:text-sm'
                placeholder='Search for products...'
                required
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button
                type='submit'
                onClick={handleSearch}
                className='flex px-3 lg:px-6 py-2.5 text-sm font-medium tracker-widest text-white bg-green-600 rounded-full hover:bg-green-800'
              >
                Search
              </button>
            </div>
          </div>

          {/* right section */}
          <div className='w-[25%] flex justify-end items-center gap-x-8'>
            <div className='flex items-center space-x-2'>
              <NavigationMenu>
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger className='!bg-inherit hover:!bg-inherit hover:!text-green-600 px-0'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='border-2 border-gray-700 rounded-full p-0.5'
                        width='30'
                        height='30'
                        viewBox='0 0 24 24'
                      >
                        <path
                          fill='currentColor'
                          d='M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4'
                        />
                      </svg>
                      <p className='pl-2 capitalize'>
                        {userProfile.first_name.length > 10
                          ? userProfile.first_name.substring(0, 10) + `...`
                          : userProfile.first_name}
                      </p>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent className='!w-[200px] !h-fit p-2 space-y-2 gap-y-2 bg-white'>
                      {isProfile ? (
                        <>
                          <Link to='/account' className='cat-link'>
                            <i className={`fa-regular fa-user`}></i>
                            Account
                          </Link>
                          <button onClick={handleLogout} className='cat-link'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 24 24'
                            >
                              <g fill='none'>
                                <path
                                  stroke='currentColor'
                                  d='M4.929 19.071a10 10 0 1 0 0-14.142'
                                />
                                <path
                                  fill='currentColor'
                                  d='m15 12l.39-.312l.25.312l-.25.312L15 12Zm-12 .5a.5.5 0 0 1 0-1v1Zm8.39-5.812l4 5l-.78.624l-4-5l.78-.624Zm4 5.624l-4 5l-.78-.624l4-5l.78.624ZM15 12.5H3v-1h12v1Z'
                                />
                              </g>
                            </svg>
                            Logout
                          </button>
                        </>
                      ) : (
                        <>
                          <Link to='/login' className='cat-link'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 24 24'
                            >
                              <g fill='none'>
                                <path
                                  stroke='currentColor'
                                  d='M4.929 19.071a10 10 0 1 0 0-14.142'
                                />
                                <path
                                  fill='currentColor'
                                  d='m15 12l.39-.312l.25.312l-.25.312L15 12Zm-12 .5a.5.5 0 0 1 0-1v1Zm8.39-5.812l4 5l-.78.624l-4-5l.78-.624Zm4 5.624l-4 5l-.78-.624l4-5l.78.624ZM15 12.5H3v-1h12v1Z'
                                />
                              </g>
                            </svg>
                            Login
                          </Link>
                          <Link to='/signup' className='cat-link'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='21'
                              height='21'
                              viewBox='0 0 256 256'
                            >
                              <path
                                fill='currentColor'
                                d='M229.19 213c-15.81-27.32-40.63-46.49-69.47-54.62a70 70 0 1 0-63.44 0C67.44 166.5 42.62 185.67 26.81 213a6 6 0 1 0 10.38 6c19.21-33.19 53.15-53 90.81-53s71.6 19.81 90.81 53a6 6 0 1 0 10.38-6ZM70 96a58 58 0 1 1 58 58a58.07 58.07 0 0 1-58-58Z'
                              />
                            </svg>
                            Sign up
                          </Link>
                        </>
                      )}
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
              <Link
                to='https://tawk.to/chat/663175f0a0c6737bd133177e/1hsoja0tr'
                target='_blank'
                className='!bg-inherit hover:!bg-inherit hover:!text-green-600 px-0'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='border-2 border-gray-700 rounded-full p-0.5 !rotate-[290deg]'
                  width='30'
                  height='30'
                  viewBox='0 0 24 24'
                >
                  <path
                    fill='currentColor'
                    d='M12.36 4C6.58 4 2.644 9.857 4.824 15.21l.933 2.288a.5.5 0 0 1-.15.579L3.634 19.66a.5.5 0 0 0 .313.89h7.82a8.732 8.732 0 0 0 8.733-8.732C20.5 7.5 17 4 12.682 4z'
                  />
                </svg>
              </Link>
            </div>
            <Link
              to='/cart'
              className='relative flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-full whitespace-nowrap gap-x-2 hover:bg-green-700'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 2048 1792'
              >
                <path
                  fill='currentColor'
                  d='M1920 768q53 0 90.5 37.5T2048 896t-37.5 90.5t-90.5 37.5h-15l-115 662q-8 46-44 76t-82 30H384q-46 0-82-30t-44-76l-115-662h-15q-53 0-90.5-37.5T0 896t37.5-90.5T128 768zM485 1568q26-2 43.5-22.5T544 1499l-32-416q-2-26-22.5-43.5T443 1024t-43.5 22.5T384 1093l32 416q2 25 20.5 42t43.5 17zm411-64v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m384 0v-416q0-26-19-45t-45-19t-45 19t-19 45v416q0 26 19 45t45 19t45-19t19-45m352 5l32-416q2-26-15.5-46.5T1605 1024t-46.5 15.5t-22.5 43.5l-32 416q-2 26 15.5 46.5t43.5 22.5h5q25 0 43.5-17t20.5-42M476 292l-93 412H251l101-441q19-88 89-143.5T601 64h167q0-26 19-45t45-19h384q26 0 45 19t19 45h167q90 0 160 55.5t89 143.5l101 441h-132l-93-412q-11-44-45.5-72t-79.5-28h-167q0 26-19 45t-45 19H832q-26 0-45-19t-19-45H601q-45 0-79.5 28T476 292'
                />
              </svg>
              <span className='lg:hidden xl:flex'>Cart</span>
              <span className='absolute right-0 w-6 h-6 m-auto text-sm font-semibold text-center text-gray-700 bg-white rounded-full shadow -top-2'>
                {isSucessCarts ? cartItem.data.length : cartCookie.length}
              </span>
            </Link>
          </div>
        </div>
      </header>

      {/* mobile pop-up */}
      {isMobileMenuOpen && (
        <div className='lg:hidden fixed left-0 whitespace-nowrap overflow-y-scroll overflow-x-hidden text-gray-800 mt-1.5 pb-20 w-full h-screen bg-tkzBg rounded-b-3xl drop-shadow z-10'>
          <div className='px-10 pt-8 border-b-2 border-gray-700'>
            <div className='flex items-center justify-between w-full'>
              <div className='flex items-center justify-between w-full'>
                <div className='relative flex items-center mb-3 ww-full gap-x-1'>
                  <svg
                    className='p-1 text-white bg-green-600 rounded-full'
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    viewBox='0 0 24 24'
                  >
                    <path
                      fill='currentColor'
                      d='M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4'
                    />
                  </svg>

                  <p className='mobile-menu-heading ms-2'>
                    {userProfile.first_name.length > 10
                      ? userProfile.first_name.substring(0, 10) + `...`
                      : userProfile.first_name}
                  </p>
                  {!isProfile && (
                    <Link
                      to='/login'
                      onClick={() => setMobileMenuOpen(false)}
                      className='text-sm hover:text-green-700'
                    >
                      Log in
                    </Link>
                  )}
                </div>
                {!isProfile && (
                  <Link to='/signup' className='w-fit checkout-btn'>
                    Sign up
                  </Link>
                )}
                {isProfile && (
                  <button
                    onClick={(event) => handleLogout(event)}
                    className='text-sm hover:text-green-700'
                  >
                    Logout
                  </button>
                )}
              </div>
            </div>

            <div className='grid grid-rows-3 mt-6 gap-y-8'>
              <div className='flex justify-between'>
                <Link
                  to='account/orders'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Orders
                </Link>
                <Link
                  to='/account/coupons'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Coupons
                </Link>
              </div>
              <div className='flex justify-between'>
                <Link
                  to='/account/login-security'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Login and security
                </Link>
                <Link
                  to='/account'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Account
                </Link>
              </div>
            </div>
          </div>

          {/* mobile menu */}
          <div className='px-10 py-8 border-b-2 border-gray-700'>
            <h2 className='mobile-menu-heading'>Categories</h2>
            <ul className='grid grid-cols-3 grid-rows-2 mt-6 gap-x-12 gap-y-4'>
              {isSuccess ? (
                categories.data.map((category: any) => (
                  <Link
                    to={`category/${category.slug}`}
                    onClick={() => setMobileMenuOpen(false)}
                    key={category.id}
                    className='flex flex-col items-center justify-center w-full text-xs font-medium text-center transition-all duration-500 ease-in-out transform hover:text-green-700 gap-y-2'
                  >
                    <div className='overflow-hidden rounded-full'>
                      <img
                        src={categorySVGs[category.name]}
                        alt={category.name}
                        width={300}
                        height={300}
                        className='object-cover w-16 h-16 transition duration-300 ease-in-out border rounded-full hover:-translate-y-1 hover:scale-125 drop-shadow-md'
                      />
                    </div>
                    <span>{category.name}</span>
                  </Link>
                ))
              ) : (
                <span className='text-xs'>Loading...</span>
              )}
            </ul>
          </div>

          <div className='px-10 py-8'>
            <h4 className='mobile-menu-heading'>Other links</h4>
            <div className='mt-6 space-y-6'>
              <div className='flex justify-between'>
                <Link
                  to='about-us'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  About us
                </Link>
                <Link
                  to='customer-support'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Customer support
                </Link>
              </div>
              <div className='flex justify-between'>
                <Link
                  to='/'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Terms and conditions
                </Link>
                <Link
                  to='/'
                  onClick={() => setMobileMenuOpen(false)}
                  className='mobile-menu-item'
                >
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </MaxWidthWrapper>
  );
};

export default Header;
