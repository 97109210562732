import Cookies from 'js-cookie';
import EncryptionHelper from "./encryption.helper";

const CookieHelper = {
  get: (key: string) => {
    const dataEncrypted = Cookies.get(key) ?? '';
    return dataEncrypted !== 'null' ? EncryptionHelper.decrypt(dataEncrypted) : '';
  },
  set: (name: string, value: any, ex: number) => {
    const dataEncrypted = EncryptionHelper.encrypt(value) as unknown as string;
    Cookies.set(name, dataEncrypted, {expires: ex});
  },
  remove: (key: string) => {
    Cookies.remove(key);
  }
}

export default CookieHelper;