import React, { useEffect, useLayoutEffect } from 'react';
import Signup from './pages/auth/Signup';
import Login from './pages/auth/Login';
import AppLayout from './components/layout/AppLayout';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import CustomerSupport from './pages/CustomerSupport';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './pages/Checkout';
import Cart from './pages/Cart';
import Category from './pages/Category';
import Auth4 from './pages/auth/Auth4';
import Auth5 from './pages/auth/Auth5';
import CheckoutAddress from './pages/CheckoutAdress';
import Account from './pages/Account';
import Product from './pages/Product';
import AccountLayout from './components/layout/AccountLayout';
import Addresses from './pages/account/Addresses';
import Transactions from './pages/account/Transactions';
import Coupons from './pages/account/Coupons';
import LoginSecurity from './pages/account/LoginSecurity';
import Orders from './pages/account/Orders';
import OrderItems from './pages/account/SavedItems';
import ForgotPassword from './pages/auth/ForgotPassword';
import AllProducts from './pages/AllProducts';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import RecipeDetails from './pages/RecipeDetails';

function App() {
  return (
    <main className='min-h-screen'>
      {/* <TawkMessengerReact propertyId='property_id' widgetId='default' /> */}
      <BrowserRouter>
        <Routes>
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route path='/auth-4' element={<Auth4 />} />
          <Route path='/auth-5' element={<Auth5 />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route element={<AppLayout />}>
            <Route path='/' element={<Home />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/account' element={<Account />} />
            <Route path='/customer-support' element={<CustomerSupport />} />
            <Route path='/cart' element={<Cart />} />
            <Route
              path='/allproducts/search/:search'
              element={<AllProducts />}
            />
            <Route path='/product/:productSlug' element={<Product />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/checkout-address' element={<CheckoutAddress />} />
            <Route path='/category/:categorySlug' element={<Category />} />
            <Route path='/account' element={<Account />} />
            <Route
              path='/recipe-details/:recipeSlug'
              element={<RecipeDetails />}
            />
            <Route path='/account' element={<AccountLayout />}>
              <Route path='addresses' element={<Addresses />} />
              <Route path='transactions' element={<Transactions />} />
              <Route path='coupons' element={<Coupons />} />
              <Route path='login-security' element={<LoginSecurity />} />
              <Route path='orders' element={<Orders />} />
              <Route path='saved-items' element={<OrderItems />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
