import { Link } from 'react-router-dom';
// import { ReactNode } from 'react';

// interface OrderCardProps {
//   items: {
//     id: string;
//     title: string;
//     link: string;
//     desc: string;
//     svg?: ReactNode;
//   }[];
// }

const OrderCard = ({ items }: any) => (
  <>
    {items &&
      items.map((item: any) => (
        <Link
          key={item.id}
          to={item.link}
          className='w-full group hover:bg-green-400 flex items-center gap-x-3 p-4 bg-white rounded-lg shadow'
        >
          <div className='bg-green-400 text-white group-hover:text-green-400 p-4 rounded-full group-hover:bg-white'>
            {item.svg}
          </div>
          <div>
            <h4 className='text-sm text-green-600 font-medium group-hover:text-white mb-1'>
              {item.title}
            </h4>
            <p className='leading-tight text-xs group-hover:text-gray-900'>
              {item.desc}
            </p>
          </div>
        </Link>
      ))}
  </>
);

export default OrderCard;