import React from 'react';
import PriceFormatter from './../config/formatters/priceFormatter';
import taikezProductImage from '../images/default_image.png';
import CartBtn from './CartBtn';
import { Link } from 'react-router-dom';

type ProductCardProps = {
  products: any[];
  isSuccess?: boolean
};

const ProductCard: React.FC<ProductCardProps> = ({ products = [], isSuccess }) => {
  return (
    <div className='product-grid'>
      {products.length > 0 ? (
        products.map(
          (product: {
            id: number;
            name: string;
            image: any;
            price: number;
            sku: string;
            stock: number;
          }) => (
            <div
              key={product.id}
              className='flex flex-col flex-wrap w-full mx-auto bg-white shadow-sm rounded-3xl'
            >
              {product.stock > 0 ? (
                <Link
                  to={`/product/${product.sku}`}
                  key={product.id}
                  className='w-full overflow-hidden rounded-3xl'
                >
                  <img
                    src={isSuccess ? (product.image.length > 0 ? product.image[0].url : taikezProductImage) : taikezProductImage}
                    alt={product.name}
                    width={200}
                    height={200}
                    className='object-cover text-xs w-full md:h-[15rem] lg:h-[16rem] xl:h-[10rem] p-1 md:p-2 rounded-3xl mb-2 shadow product-animate'
                  />
                </Link>
              ) : (
                <div className='relative w-full overflow-hidden cursor-not-allowed rounded-3xl'>
                  <img
                    src={isSuccess ? (product.image.length > 0 ? product.image[0]?.url : taikezProductImage) : taikezProductImage}
                    alt={product.name}
                    width={200}
                    height={200}
                    className='object-cover text-xs w-full md:h-[15rem] lg:h-[16rem] xl:h-[10rem] p-1 md:p-2 rounded-3xl mb-2 shadow product-animate opacity-50'
                  />
                  <div className='absolute inset-0 flex items-center justify-center text-lg font-bold text-white bg-black bg-opacity-50'>
                    Out of Stock
                  </div>
                </div>
              )}

              <div className='product-name-price'>
                <p className='product-name'>{product.name}</p>
                <p className='product-price'>
                  ₦ {PriceFormatter(product.price, false)}
                </p>
              </div>
              <CartBtn productSku={product.sku} stock={product.stock} />
            </div>
          )
        )
      ) : (
        <div>Loading..</div>
      )}
    </div>
  );
};

export default ProductCard;
