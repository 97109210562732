import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from "@reduxjs/toolkit/query";


// import {apiQuery} from "./slices/api.slice";
import authReducer from './slices/auth.slice';
import { apiQuery } from './slices/api.slice';
import { createLogger } from 'redux-logger';

const loggerMiddleware = () => {
  if (process.env.NODE_ENV === 'development') return [createLogger({collapsed: true})];
  return [];
};

export const store = configureStore({
  reducer: {
    [apiQuery.reducerPath]: apiQuery.reducer,
    auth: authReducer
  },
  middleware: initMiddleware => initMiddleware().concat(apiQuery.middleware).concat(loggerMiddleware()),
  devTools: false
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);