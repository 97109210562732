import React from 'react';
import { Link } from 'react-router-dom';

interface CardProps {
  heading: string;
  subHeading?: string;
  text: string;
  link?: string
  address?: any
}

const Card: React.FC<CardProps> = ({ heading, text, link, address }) => {
  return (
    <div className='w-full p-4 space-y-1 md:space-y-4 bg-white rounded-md'>
      <div className='flex justify-between items-center'>
        <h4 className='text-sm font-semibold'>{heading}</h4>
        <input
          id='inline-radio'
          type='radio'
          value=''
          name='inline-radio-group'
          className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500'
        />
      </div>
      <div className='flex items-center justify-between'>
        {  address ?
        (
          <div>
            {
              <div className='text-sm text-green-600 hover:text-green-800 font-medium flex items-center gap-x-1'>
                {address?.address} {address?.city} {address?.state}
              </div>
            }
          </div>
        ) :
        <p className='text-sm'>{text}</p>
        //   (<div className='text-xs text-green-600 hover:text-green-800 font-medium flex items-center gap-x-1'>
        //     {link}
        // </div>)        
        }
      </div>
    </div>
  );
};

export default Card;
