import {createSlice, Dispatch, Store} from '@reduxjs/toolkit'
import TokenHelper from '../../config/utils/token.helper';
import AccountService from '../../config/services/account.service';
import CookieHelper from '../../config/utils/cookie.helper';
import AuthService from "../../config/services/auth.service";
import { AppKeys } from '../../config/utils/constants';
// import {MockProfile} from "../../mocks/MockProfile";


const initialState: IAuthState = {
  token: '',
  userProfile: {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    addresses: []
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: IAuthState, {payload: access}): void => {
      state.token = access
    },
    setProfile: (state: IAuthState, {payload: userProfile}): void => {
      state.userProfile = userProfile
    }
  }
})

export const onAuthentication = (dispatch: Dispatch, access: string): void => {
  TokenHelper.saveToken(access)
  const userAuthenticated: boolean = TokenHelper.authenticationValid()
  if (userAuthenticated) getProfile(dispatch).catch(err => console.log(err))
}

export const onRTKPersist = (store: Store): void => {
  let userAuthenticated: boolean = TokenHelper.authenticationValid()
  if (userAuthenticated) getProfile(store.dispatch).catch(err => console.log(err))
}

export const getProfile = async (dispatch: Dispatch): Promise<void> => {
  try {
    let token = TokenHelper.getToken()
    await Promise.all([await AccountService.getProfile()]).then(response => {
      dispatch(setAuth(token))
      dispatch(setProfile(response[0]['data']['data']))
    });
  } catch (error: any) {
    if (error && error.status === 401) {
      CookieHelper.remove(AppKeys.TOKEN)
      window.location.href = '/login';
    } else console.error(error)
  }
}

export const removeAuthentication = async (): Promise<void> => {
  const {data: responseData} = await AuthService.logout()
  if(responseData.status)
  CookieHelper.remove(AppKeys.TOKEN)
  localStorage.clear()
  window.location.href = '/login';
}


export const {setAuth, setProfile} = authSlice.actions

export default authSlice.reducer;
