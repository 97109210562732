import HeroMobileSvg from 'src/images/slider_banners/heroTextMobile.png';

const Hero = () => {
  return (
    <div className='w-full flex flex-col justify-between items-center mx-auto mt-8 text-center space-y-5 mb-5 md:mb-20 overflow-hidden'>
      <img
        src={HeroMobileSvg}
        alt='Taikez mobile banner'
        width={1000}
        height={1000}
        className='object-fit lg:hidden mt-6 rounded-md md:rounded-md w-full h-full md:w-[80%]'
      />
      <div className='mx-auto hidden lg:block text-base'>
        <svg
          width='733'
          height='113'
          viewBox='0 0 733 113'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='w-[60%] mx-auto'
        >
          <path
            d='M590.312 86.8832L588.776 71.8165V60.4453H597.589V71.8165L596.111 86.8832H590.312ZM596.623 99.5621C595.675 100.51 594.538 100.983 593.211 100.983C591.885 100.983 590.747 100.51 589.8 99.5621C588.852 98.6145 588.378 97.4774 588.378 96.1507C588.378 94.8241 588.852 93.687 589.8 92.7394C590.747 91.7918 591.885 91.318 593.211 91.318C594.538 91.318 595.675 91.7918 596.623 92.7394C597.57 93.687 598.044 94.8241 598.044 96.1507C598.044 97.4774 597.57 98.6145 596.623 99.5621Z'
            fill='#06070E'
          />
          <path
            d='M569.284 79.8988C569.284 80.6569 569.777 81.2823 570.763 81.775C571.786 82.2299 573.018 82.6468 574.458 83.0259C575.899 83.367 577.339 83.8218 578.779 84.3904C580.22 84.9211 581.433 85.8307 582.418 87.1195C583.441 88.4082 583.953 90.0191 583.953 91.9522C583.953 94.8708 582.854 97.1261 580.656 98.718C578.495 100.272 575.785 101.049 572.525 101.049C566.688 101.049 562.708 98.7939 560.585 94.2833L566.953 90.7014C567.787 93.1651 569.645 94.397 572.525 94.397C575.141 94.397 576.448 93.5821 576.448 91.9522C576.448 91.1941 575.937 90.5877 574.913 90.1328C573.928 89.6401 572.715 89.2042 571.274 88.8251C569.834 88.4461 568.394 87.9723 566.953 87.4038C565.513 86.8352 564.281 85.9445 563.258 84.7315C562.272 83.4807 561.779 81.9266 561.779 80.0694C561.779 77.2645 562.803 75.0661 564.85 73.4741C566.934 71.8442 569.512 71.0293 572.582 71.0293C574.894 71.0293 576.998 71.56 578.893 72.6213C580.788 73.6447 582.285 75.1229 583.385 77.056L577.131 80.4674C576.221 78.5343 574.705 77.5677 572.582 77.5677C571.634 77.5677 570.838 77.7762 570.194 78.1931C569.588 78.6101 569.284 79.1786 569.284 79.8988Z'
            fill='#06070E'
          />
          <path
            d='M557.958 78.8927H551.534V90.7187C551.534 91.7042 551.78 92.4244 552.273 92.8792C552.766 93.3341 553.486 93.5994 554.433 93.6752C555.381 93.7131 556.556 93.6942 557.958 93.6184V100.27C552.917 100.839 549.354 100.365 547.27 98.8491C545.223 97.3329 544.199 94.6228 544.199 90.7187V78.8927H539.253V71.8426H544.199V66.1002L551.534 63.8828V71.8426H557.958V78.8927Z'
            fill='#06070E'
          />
          <path
            d='M524.69 71.0205C527.798 71.0205 530.356 72.0628 532.365 74.1475C534.412 76.2323 535.435 79.113 535.435 82.7896V100.244H528.101V83.6993C528.101 81.8041 527.589 80.3638 526.566 79.3783C525.543 78.3549 524.178 77.8432 522.472 77.8432C520.577 77.8432 519.061 78.4307 517.924 79.6057C516.787 80.7807 516.218 82.5433 516.218 84.8933V100.244H508.884V60.4453H516.218V75.0004C518 72.3471 520.823 71.0205 524.69 71.0205Z'
            fill='#06070E'
          />
          <path
            d='M495.068 71.8253H502.232V98.8886C502.232 103.285 500.772 106.64 497.854 108.952C494.973 111.264 491.486 112.42 487.392 112.42C484.322 112.42 481.612 111.833 479.262 110.658C476.95 109.483 475.168 107.758 473.918 105.484L480.172 101.902C481.612 104.555 484.095 105.882 487.62 105.882C489.932 105.882 491.751 105.275 493.078 104.062C494.405 102.85 495.068 101.125 495.068 98.8886V95.8184C492.907 98.6991 489.875 100.139 485.971 100.139C481.953 100.139 478.58 98.737 475.851 95.9321C473.16 93.0893 471.814 89.6401 471.814 85.5844C471.814 81.5666 473.178 78.1363 475.908 75.2935C478.637 72.4507 481.991 71.0293 485.971 71.0293C489.875 71.0293 492.907 72.4696 495.068 75.3503V71.8253ZM481.366 91.2131C482.882 92.6534 484.796 93.3736 487.108 93.3736C489.42 93.3736 491.315 92.6534 492.794 91.2131C494.31 89.7348 495.068 87.8586 495.068 85.5844C495.068 83.348 494.31 81.5097 492.794 80.0694C491.315 78.5911 489.42 77.852 487.108 77.852C484.796 77.852 482.882 78.5911 481.366 80.0694C479.887 81.5097 479.148 83.348 479.148 85.5844C479.148 87.8586 479.887 89.7348 481.366 91.2131Z'
            fill='#06070E'
          />
          <path
            d='M462.886 68.4092C461.673 68.4092 460.611 67.9733 459.702 67.1015C458.83 66.1918 458.394 65.1305 458.394 63.9176C458.394 62.7047 458.83 61.6434 459.702 60.7337C460.611 59.824 461.673 59.3691 462.886 59.3691C464.136 59.3691 465.198 59.824 466.07 60.7337C466.979 61.6434 467.434 62.7047 467.434 63.9176C467.434 65.1305 466.979 66.1918 466.07 67.1015C465.198 67.9733 464.136 68.4092 462.886 68.4092ZM459.247 100.248V71.8205H466.581V100.248H459.247Z'
            fill='#06070E'
          />
          <path
            d='M445.199 100.257V58.752H452.533V100.257H445.199Z'
            fill='#06070E'
          />
          <path
            d='M418.359 89.0526C419.345 92.6155 422.017 94.397 426.376 94.397C429.181 94.397 431.303 93.4494 432.744 91.5542L438.657 94.9656C435.852 99.0213 431.72 101.049 426.262 101.049C421.562 101.049 417.791 99.6277 414.948 96.785C412.105 93.9422 410.684 90.3603 410.684 86.0392C410.684 81.7561 412.086 78.1931 414.891 75.3503C417.696 72.4696 421.297 71.0293 425.694 71.0293C429.863 71.0293 433.293 72.4696 435.984 75.3503C438.713 78.231 440.078 81.794 440.078 86.0392C440.078 86.9868 439.983 87.9913 439.794 89.0526H418.359ZM418.245 83.367H432.744C432.327 81.4339 431.455 79.9936 430.128 79.046C428.84 78.0984 427.361 77.6246 425.694 77.6246C423.723 77.6246 422.093 78.1363 420.804 79.1597C419.515 80.1452 418.662 81.5476 418.245 83.367Z'
            fill='#06070E'
          />
          <path
            d='M398.12 60.4453H405.454V100.244H398.12V96.8898C395.959 99.6568 392.889 101.04 388.909 101.04C385.081 101.04 381.802 99.6 379.073 96.7193C376.382 93.8007 375.036 90.2377 375.036 86.0304C375.036 81.8231 376.382 78.2791 379.073 75.3984C381.802 72.4798 385.081 71.0205 388.909 71.0205C392.889 71.0205 395.959 72.404 398.12 75.171V60.4453ZM384.588 91.8297C386.104 93.3079 387.999 94.0471 390.273 94.0471C392.548 94.0471 394.424 93.3079 395.902 91.8297C397.38 90.3135 398.12 88.3804 398.12 86.0304C398.12 83.6804 397.38 81.7662 395.902 80.288C394.424 78.7718 392.548 78.0137 390.273 78.0137C387.999 78.0137 386.104 78.7718 384.588 80.288C383.11 81.7662 382.371 83.6804 382.371 86.0304C382.371 88.3804 383.11 90.3135 384.588 91.8297Z'
            fill='#06070E'
          />
          <path
            d='M356.744 78.8927H350.319V90.7187C350.319 91.7042 350.565 92.4244 351.058 92.8792C351.551 93.3341 352.271 93.5994 353.219 93.6752C354.166 93.7131 355.341 93.6942 356.744 93.6184V100.27C351.702 100.839 348.139 100.365 346.055 98.8491C344.008 97.3329 342.985 94.6228 342.985 90.7187V78.8927H338.038V71.8426H342.985V66.1002L350.319 63.8828V71.8426H356.744V78.8927Z'
            fill='#06070E'
          />
          <path
            d='M314.477 89.0526C315.462 92.6155 318.135 94.397 322.493 94.397C325.298 94.397 327.421 93.4494 328.861 91.5542L334.774 94.9656C331.969 99.0213 327.838 101.049 322.38 101.049C317.68 101.049 313.908 99.6277 311.065 96.785C308.223 93.9422 306.801 90.3603 306.801 86.0392C306.801 81.7561 308.204 78.1931 311.009 75.3503C313.813 72.4696 317.414 71.0293 321.811 71.0293C325.981 71.0293 329.411 72.4696 332.102 75.3503C334.831 78.231 336.196 81.794 336.196 86.0392C336.196 86.9868 336.101 87.9913 335.911 89.0526H314.477ZM314.363 83.367H328.861C328.444 81.4339 327.573 79.9936 326.246 79.046C324.957 78.0984 323.479 77.6246 321.811 77.6246C319.84 77.6246 318.21 78.1363 316.922 79.1597C315.633 80.1452 314.78 81.5476 314.363 83.367Z'
            fill='#06070E'
          />
          <path
            d='M291.306 71.0293C294.566 71.0293 297.162 72.0906 299.095 74.2132C301.066 76.3358 302.052 79.1786 302.052 82.7416V100.253H294.717V83.2533C294.717 81.5476 294.3 80.221 293.467 79.2734C292.633 78.3258 291.458 77.852 289.941 77.852C288.274 77.852 286.966 78.4016 286.018 79.5008C285.109 80.6 284.654 82.192 284.654 84.2767V100.253H277.319V83.2533C277.319 81.5476 276.903 80.221 276.069 79.2734C275.235 78.3258 274.06 77.852 272.544 77.852C270.914 77.852 269.606 78.4016 268.621 79.5008C267.673 80.6 267.199 82.192 267.199 84.2767V100.253H259.865V71.8253H267.199V74.8386C268.905 72.2991 271.539 71.0293 275.102 71.0293C278.589 71.0293 281.167 72.3938 282.834 75.1229C284.73 72.3938 287.553 71.0293 291.306 71.0293Z'
            fill='#06070E'
          />
          <path
            d='M246.932 76.7179C247.614 74.8985 248.732 73.534 250.287 72.6243C251.879 71.7146 253.641 71.2598 255.574 71.2598V79.447C253.338 79.1817 251.329 79.6365 249.547 80.8115C247.804 81.9866 246.932 83.9386 246.932 86.6677V100.256H239.598V71.8283H246.932V76.7179Z'
            fill='#06070E'
          />
          <path
            d='M225.547 71.8125H232.882V100.24H225.547V97.0565C223.766 99.7097 220.942 101.036 217.076 101.036C213.968 101.036 211.39 99.994 209.343 97.9093C207.335 95.8246 206.33 92.9439 206.33 89.2672V71.8125H213.664V88.3575C213.664 90.2527 214.176 91.712 215.2 92.7354C216.223 93.7209 217.588 94.2137 219.293 94.2137C221.188 94.2137 222.705 93.6262 223.842 92.4511C224.979 91.2761 225.547 89.5136 225.547 87.1635V71.8125Z'
            fill='#06070E'
          />
          <ellipse
            cx='187.641'
            cy='85.6636'
            rx='9.06396'
            ry='9.06396'
            fill='#FD393D'
          />
          <path
            d='M197.245 96.73C194.327 99.6107 190.764 101.051 186.556 101.051C182.349 101.051 178.786 99.6107 175.867 96.73C172.987 93.8115 171.546 90.2485 171.546 86.0412C171.546 81.8338 172.987 78.2898 175.867 75.4091C178.786 72.4906 182.349 71.0312 186.556 71.0312C190.764 71.0312 194.327 72.4906 197.245 75.4091C200.164 78.2898 201.623 81.8338 201.623 86.0412C201.623 90.2485 200.164 93.8115 197.245 96.73ZM181.041 91.6699C182.52 93.1481 184.358 93.8873 186.556 93.8873C188.755 93.8873 190.593 93.1481 192.071 91.6699C193.55 90.1916 194.289 88.3154 194.289 86.0412C194.289 83.7669 193.55 81.8907 192.071 80.4124C190.593 78.9342 188.755 78.1951 186.556 78.1951C184.358 78.1951 182.52 78.9342 181.041 80.4124C179.601 81.8907 178.881 83.7669 178.881 86.0412C178.881 88.3154 179.601 90.1916 181.041 91.6699Z'
            fill='#06070E'
          />
          <path
            d='M159.212 71.8253H166.376V98.8886C166.376 103.285 164.917 106.64 161.998 108.952C159.117 111.264 155.63 112.42 151.536 112.42C148.466 112.42 145.756 111.833 143.406 110.658C141.094 109.483 139.312 107.758 138.062 105.484L144.316 101.902C145.756 104.555 148.239 105.882 151.764 105.882C154.076 105.882 155.895 105.275 157.222 104.062C158.549 102.85 159.212 101.125 159.212 98.8886V95.8184C157.051 98.6991 154.019 100.139 150.115 100.139C146.097 100.139 142.724 98.737 139.995 95.9321C137.304 93.0893 135.958 89.6401 135.958 85.5844C135.958 81.5666 137.323 78.1363 140.052 75.2935C142.781 72.4507 146.135 71.0293 150.115 71.0293C154.019 71.0293 157.051 72.4696 159.212 75.3503V71.8253ZM145.51 91.2131C147.026 92.6534 148.94 93.3736 151.252 93.3736C153.564 93.3736 155.46 92.6534 156.938 91.2131C158.454 89.7348 159.212 87.8586 159.212 85.5844C159.212 83.348 158.454 81.5097 156.938 80.0694C155.46 78.5911 153.564 77.852 151.252 77.852C148.94 77.852 147.026 78.5911 145.51 80.0694C144.032 81.5097 143.292 83.348 143.292 85.5844C143.292 87.8586 144.032 89.7348 145.51 91.2131Z'
            fill='#06070E'
          />
          <path
            d='M728.573 37.1148C725.655 39.9955 722.092 41.4358 717.884 41.4358C713.677 41.4358 710.114 39.9955 707.195 37.1148C704.315 34.1962 702.874 30.6333 702.874 26.4259C702.874 22.2186 704.315 18.6746 707.195 15.7939C710.114 12.8753 713.677 11.416 717.884 11.416C722.092 11.416 725.655 12.8753 728.573 15.7939C731.492 18.6746 732.951 22.2186 732.951 26.4259C732.951 30.6333 731.492 34.1962 728.573 37.1148ZM712.369 32.0547C713.847 33.5329 715.686 34.272 717.884 34.272C720.083 34.272 721.921 33.5329 723.399 32.0547C724.877 30.5764 725.617 28.7002 725.617 26.4259C725.617 24.1517 724.877 22.2755 723.399 20.7972C721.921 19.319 720.083 18.5798 717.884 18.5798C715.686 18.5798 713.847 19.319 712.369 20.7972C710.929 22.2755 710.209 24.1517 710.209 26.4259C710.209 28.7002 710.929 30.5764 712.369 32.0547Z'
            fill='#06070E'
          />
          <path
            d='M700.07 19.2794H693.645V31.1054C693.645 32.0909 693.891 32.8111 694.384 33.266C694.877 33.7208 695.597 33.9861 696.544 34.0619C697.492 34.0998 698.667 34.0809 700.07 34.0051V40.6572C695.028 41.2258 691.465 40.752 689.381 39.2358C687.334 37.7197 686.31 35.0095 686.31 31.1054V19.2794H681.364V12.2293H686.31V6.48691L693.645 4.26953V12.2293H700.07V19.2794Z'
            fill='#06070E'
          />
          <path
            d='M649.932 20.2855C649.932 21.0436 650.425 21.669 651.41 22.1617C652.434 22.6166 653.665 23.0335 655.106 23.4126C656.546 23.7537 657.986 24.2086 659.427 24.7771C660.867 25.3078 662.08 26.2175 663.066 27.5062C664.089 28.7949 664.601 30.4058 664.601 32.3389C664.601 35.2575 663.501 37.5128 661.303 39.1048C659.143 40.6588 656.432 41.4358 653.173 41.4358C647.335 41.4358 643.356 39.1806 641.233 34.67L647.601 31.0881C648.435 33.5519 650.292 34.7837 653.173 34.7837C655.788 34.7837 657.096 33.9688 657.096 32.3389C657.096 31.5809 656.584 30.9744 655.561 30.5195C654.575 30.0268 653.362 29.5909 651.922 29.2119C650.481 28.8328 649.041 28.359 647.601 27.7905C646.16 27.2219 644.929 26.3312 643.905 25.1182C642.92 23.8674 642.427 22.3134 642.427 20.4561C642.427 17.6512 643.45 15.4528 645.497 13.8608C647.582 12.2309 650.159 11.416 653.229 11.416C655.542 11.416 657.645 11.9467 659.54 13.008C661.436 14.0314 662.933 15.5096 664.032 17.4427L657.778 20.8541C656.868 18.921 655.352 17.9544 653.229 17.9544C652.282 17.9544 651.486 18.1629 650.842 18.5798C650.235 18.9968 649.932 19.5653 649.932 20.2855Z'
            fill='#06070E'
          />
          <path
            d='M617.438 29.4393C618.423 33.0022 621.096 34.7837 625.455 34.7837C628.26 34.7837 630.382 33.8361 631.823 31.9409L637.735 35.3523C634.931 39.408 630.799 41.4358 625.341 41.4358C620.641 41.4358 616.869 40.0145 614.027 37.1717C611.184 34.3289 609.762 30.747 609.762 26.4259C609.762 22.1428 611.165 18.5798 613.97 15.737C616.775 12.8564 620.376 11.416 624.772 11.416C628.942 11.416 632.372 12.8564 635.063 15.737C637.792 18.6177 639.157 22.1807 639.157 26.4259C639.157 27.3735 639.062 28.378 638.873 29.4393H617.438ZM617.324 23.7537H631.823C631.406 21.8206 630.534 20.3803 629.207 19.4327C627.918 18.4851 626.44 18.0113 624.772 18.0113C622.801 18.0113 621.172 18.523 619.883 19.5464C618.594 20.5319 617.741 21.9343 617.324 23.7537Z'
            fill='#06070E'
          />
          <path
            d='M599.365 17.1046C600.047 15.2853 601.165 13.9207 602.719 13.011C604.311 12.1013 606.074 11.6465 608.007 11.6465V19.8337C605.77 19.5684 603.762 20.0232 601.98 21.1983C600.236 22.3733 599.365 24.3253 599.365 27.0544V40.6429H592.03V12.215H599.365V17.1046Z'
            fill='#06070E'
          />
          <path
            d='M577.979 12.1953H585.314V40.6232H577.979V37.4393C576.198 40.0925 573.374 41.4192 569.508 41.4192C566.4 41.4192 563.822 40.3768 561.776 38.2921C559.767 36.2074 558.762 33.3267 558.762 29.65V12.1953H566.097V28.7403C566.097 30.6355 566.608 32.0948 567.632 33.1182C568.655 34.1037 570.02 34.5965 571.725 34.5965C573.621 34.5965 575.137 34.009 576.274 32.8339C577.411 31.6589 577.979 29.8964 577.979 27.5464V12.1953Z'
            fill='#06070E'
          />
          <path
            d='M539.832 20.2855C539.832 21.0436 540.325 21.669 541.31 22.1617C542.333 22.6166 543.565 23.0335 545.006 23.4126C546.446 23.7537 547.886 24.2086 549.327 24.7771C550.767 25.3078 551.98 26.2175 552.965 27.5062C553.989 28.7949 554.501 30.4058 554.501 32.3389C554.501 35.2575 553.401 37.5128 551.203 39.1048C549.042 40.6588 546.332 41.4358 543.073 41.4358C537.235 41.4358 533.255 39.1806 531.133 34.67L537.501 31.0881C538.335 33.5519 540.192 34.7837 543.073 34.7837C545.688 34.7837 546.996 33.9688 546.996 32.3389C546.996 31.5809 546.484 30.9744 545.46 30.5195C544.475 30.0268 543.262 29.5909 541.822 29.2119C540.381 28.8328 538.941 28.359 537.501 27.7905C536.06 27.2219 534.828 26.3312 533.805 25.1182C532.82 23.8674 532.327 22.3134 532.327 20.4561C532.327 17.6512 533.35 15.4528 535.397 13.8608C537.482 12.2309 540.059 11.416 543.129 11.416C545.442 11.416 547.545 11.9467 549.44 13.008C551.336 14.0314 552.833 15.5096 553.932 17.4427L547.678 20.8541C546.768 18.921 545.252 17.9544 543.129 17.9544C542.182 17.9544 541.386 18.1629 540.741 18.5798C540.135 18.9968 539.832 19.5653 539.832 20.2855Z'
            fill='#06070E'
          />
          <ellipse
            cx='511.296'
            cy='26.0503'
            rx='9.06396'
            ry='9.06396'
            fill='#FEC401'
          />
          <path
            d='M519.302 12.2139H526.637V40.6418H519.302V37.2873C517.104 40.0543 514.015 41.4378 510.035 41.4378C506.244 41.4378 502.985 39.9975 500.256 37.1168C497.564 34.1982 496.219 30.6352 496.219 26.4279C496.219 22.2206 497.564 18.6766 500.256 15.7959C502.985 12.8773 506.244 11.418 510.035 11.418C514.015 11.418 517.104 12.8015 519.302 15.5684V12.2139ZM505.771 32.2272C507.249 33.7054 509.125 34.4445 511.399 34.4445C513.673 34.4445 515.55 33.7054 517.028 32.2272C518.544 30.711 519.302 28.7779 519.302 26.4279C519.302 24.0778 518.544 22.1637 517.028 20.6855C515.55 19.1693 513.673 18.4112 511.399 18.4112C509.125 18.4112 507.249 19.1693 505.771 20.6855C504.292 22.1637 503.553 24.0778 503.553 26.4279C503.553 28.7779 504.292 30.711 505.771 32.2272Z'
            fill='#06070E'
          />
          <path
            d='M471.081 29.4393C472.066 33.0022 474.739 34.7837 479.097 34.7837C481.902 34.7837 484.025 33.8361 485.465 31.9409L491.378 35.3523C488.573 39.408 484.442 41.4358 478.984 41.4358C474.284 41.4358 470.512 40.0145 467.669 37.1717C464.827 34.3289 463.405 30.747 463.405 26.4259C463.405 22.1428 464.808 18.5798 467.613 15.737C470.417 12.8564 474.018 11.416 478.415 11.416C482.585 11.416 486.015 12.8564 488.706 15.737C491.435 18.6177 492.8 22.1807 492.8 26.4259C492.8 27.3735 492.705 28.378 492.515 29.4393H471.081ZM470.967 23.7537H485.465C485.048 21.8206 484.177 20.3803 482.85 19.4327C481.561 18.4851 480.083 18.0113 478.415 18.0113C476.444 18.0113 474.814 18.523 473.526 19.5464C472.237 20.5319 471.384 21.9343 470.967 23.7537Z'
            fill='#06070E'
          />
          <path
            d='M453.007 17.1046C453.69 15.2853 454.808 13.9207 456.362 13.011C457.954 12.1013 459.716 11.6465 461.649 11.6465V19.8337C459.413 19.5684 457.404 20.0232 455.623 21.1983C453.879 22.3733 453.007 24.3253 453.007 27.0544V40.6429H445.673V12.215H453.007V17.1046Z'
            fill='#06070E'
          />
          <path
            d='M440.057 19.2794H433.632V31.1054C433.632 32.0909 433.879 32.8111 434.372 33.266C434.864 33.7208 435.584 33.9861 436.532 34.0619C437.48 34.0998 438.655 34.0809 440.057 34.0051V40.6572C435.016 41.2258 431.453 40.752 429.368 39.2358C427.321 37.7197 426.298 35.0095 426.298 31.1054V19.2794H421.352V12.2293H426.298V6.48691L433.632 4.26953V12.2293H440.057V19.2794Z'
            fill='#06070E'
          />
          <path
            d='M392.576 11.4072C395.685 11.4072 398.243 12.4496 400.252 14.5343C402.299 16.619 403.322 19.4997 403.322 23.1763V40.6311H395.988V24.086C395.988 22.1908 395.476 20.7505 394.453 19.765C393.429 18.7416 392.065 18.2299 390.359 18.2299C388.464 18.2299 386.948 18.8174 385.811 19.9924C384.673 21.1674 384.105 22.93 384.105 25.28V40.6311H376.771V0.832031H384.105V15.3871C385.886 12.7338 388.71 11.4072 392.576 11.4072Z'
            fill='#06070E'
          />
          <path
            d='M357.384 20.2855C357.384 21.0436 357.876 21.669 358.862 22.1617C359.885 22.6166 361.117 23.0335 362.557 23.4126C363.998 23.7537 365.438 24.2086 366.878 24.7771C368.319 25.3078 369.532 26.2175 370.517 27.5062C371.541 28.7949 372.052 30.4058 372.052 32.3389C372.052 35.2575 370.953 37.5128 368.755 39.1048C366.594 40.6588 363.884 41.4358 360.624 41.4358C354.787 41.4358 350.807 39.1806 348.685 34.67L355.052 31.0881C355.886 33.5519 357.744 34.7837 360.624 34.7837C363.24 34.7837 364.547 33.9688 364.547 32.3389C364.547 31.5809 364.036 30.9744 363.012 30.5195C362.027 30.0268 360.814 29.5909 359.373 29.2119C357.933 28.8328 356.493 28.359 355.052 27.7905C353.612 27.2219 352.38 26.3312 351.357 25.1182C350.371 23.8674 349.879 22.3134 349.879 20.4561C349.879 17.6512 350.902 15.4528 352.949 13.8608C355.033 12.2309 357.611 11.416 360.681 11.416C362.993 11.416 365.097 11.9467 366.992 13.008C368.887 14.0314 370.385 15.5096 371.484 17.4427L365.23 20.8541C364.32 18.921 362.804 17.9544 360.681 17.9544C359.734 17.9544 358.938 18.1629 358.293 18.5798C357.687 18.9968 357.384 19.5653 357.384 20.2855Z'
            fill='#06070E'
          />
          <path
            d='M324.89 29.4393C325.876 33.0022 328.548 34.7837 332.907 34.7837C335.712 34.7837 337.835 33.8361 339.275 31.9409L345.188 35.3523C342.383 39.408 338.251 41.4358 332.793 41.4358C328.093 41.4358 324.322 40.0145 321.479 37.1717C318.636 34.3289 317.215 30.747 317.215 26.4259C317.215 22.1428 318.617 18.5798 321.422 15.737C324.227 12.8564 327.828 11.416 332.225 11.416C336.394 11.416 339.824 12.8564 342.516 15.737C345.245 18.6177 346.609 22.1807 346.609 26.4259C346.609 27.3735 346.515 28.378 346.325 29.4393H324.89ZM324.777 23.7537H339.275C338.858 21.8206 337.986 20.3803 336.66 19.4327C335.371 18.4851 333.893 18.0113 332.225 18.0113C330.254 18.0113 328.624 18.523 327.335 19.5464C326.046 20.5319 325.194 21.9343 324.777 23.7537Z'
            fill='#06070E'
          />
          <path
            d='M306.816 17.1046C307.498 15.2853 308.616 13.9207 310.17 13.011C311.762 12.1013 313.525 11.6465 315.458 11.6465V19.8337C313.222 19.5684 311.213 20.0232 309.431 21.1983C307.688 22.3733 306.816 24.3253 306.816 27.0544V40.6429H299.481V12.215H306.816V17.1046Z'
            fill='#06070E'
          />
          <path
            d='M296.12 7.09505C292.102 6.79182 290.093 8.40273 290.093 11.9278V12.2121H296.12V19.2622H290.093V40.6399H282.759V19.2622H278.665V12.2121H282.759V11.9278C282.759 7.90998 283.877 4.85872 286.113 2.77401C288.349 0.689301 291.685 -0.220392 296.12 0.044934V7.09505Z'
            fill='#06070E'
          />
          <path
            d='M249.639 11.416C252.899 11.416 255.495 12.4773 257.428 14.5999C259.399 16.7225 260.385 19.5653 260.385 23.1283V40.6399H253.05V23.64C253.05 21.9343 252.633 20.6077 251.8 19.6601C250.966 18.7125 249.791 18.2387 248.274 18.2387C246.607 18.2387 245.299 18.7883 244.351 19.8875C243.442 20.9867 242.987 22.5787 242.987 24.6634V40.6399H235.652V23.64C235.652 21.9343 235.236 20.6077 234.402 19.6601C233.568 18.7125 232.393 18.2387 230.877 18.2387C229.247 18.2387 227.939 18.7883 226.954 19.8875C226.006 20.9867 225.532 22.5787 225.532 24.6634V40.6399H218.198V12.212H225.532V15.2253C227.238 12.6858 229.872 11.416 233.435 11.416C236.922 11.416 239.5 12.7806 241.167 15.5096C243.063 12.7806 245.886 11.416 249.639 11.416Z'
            fill='#06070E'
          />
          <path
            d='M205.265 17.1046C205.947 15.2853 207.065 13.9207 208.619 13.011C210.211 12.1013 211.974 11.6465 213.907 11.6465V19.8337C211.67 19.5684 209.661 20.0232 207.88 21.1983C206.136 22.3733 205.265 24.3253 205.265 27.0544V40.6429H197.93V12.215H205.265V17.1046Z'
            fill='#06070E'
          />
          <path
            d='M183.945 12.212H191.28V40.6399H183.945V37.2854C181.747 40.0524 178.658 41.4358 174.678 41.4358C170.887 41.4358 167.628 39.9955 164.899 37.1148C162.207 34.1962 160.862 30.6333 160.862 26.4259C160.862 22.2186 162.207 18.6746 164.899 15.7939C167.628 12.8753 170.887 11.416 174.678 11.416C178.658 11.416 181.747 12.7995 183.945 15.5665V12.212ZM170.414 32.2252C171.892 33.7035 173.768 34.4426 176.042 34.4426C178.317 34.4426 180.193 33.7035 181.671 32.2252C183.187 30.7091 183.945 28.776 183.945 26.4259C183.945 24.0759 183.187 22.1618 181.671 20.6835C180.193 19.1673 178.317 18.4093 176.042 18.4093C173.768 18.4093 171.892 19.1673 170.414 20.6835C168.935 22.1618 168.196 24.0759 168.196 26.4259C168.196 28.776 168.935 30.7091 170.414 32.2252Z'
            fill='#06070E'
          />
          <path
            d='M160.034 7.09505C156.017 6.79182 154.008 8.40273 154.008 11.9278V12.2121H160.034V19.2622H154.008V40.6399H146.673V19.2622H142.58V12.2121H146.673V11.9278C146.673 7.90998 147.791 4.85872 150.028 2.77401C152.264 0.689301 155.6 -0.220392 160.034 0.044934V7.09505Z'
            fill='#06070E'
          />
          <path
            d='M113.552 11.416C116.811 11.416 119.408 12.4773 121.341 14.5999C123.312 16.7225 124.297 19.5653 124.297 23.1283V40.6399H116.963V23.64C116.963 21.9343 116.546 20.6077 115.712 19.6601C114.878 18.7125 113.703 18.2387 112.187 18.2387C110.519 18.2387 109.212 18.7883 108.264 19.8875C107.354 20.9867 106.899 22.5787 106.899 24.6634V40.6399H99.5651V23.64C99.5651 21.9343 99.1481 20.6077 98.3142 19.6601C97.4804 18.7125 96.3053 18.2387 94.7892 18.2387C93.1593 18.2387 91.8516 18.7883 90.8661 19.8875C89.9185 20.9867 89.4447 22.5787 89.4447 24.6634V40.6399H82.1104V12.212H89.4447V15.2253C91.1504 12.6858 93.7847 11.416 97.3477 11.416C100.835 11.416 103.412 12.7806 105.08 15.5096C106.975 12.7806 109.799 11.416 113.552 11.416Z'
            fill='#06070E'
          />
          <ellipse
            cx='62.2246'
            cy='26.8628'
            rx='9.06396'
            ry='9.06396'
            fill='#05B048'
          />
          <path
            d='M72.5723 37.1168C69.6538 39.9975 66.0908 41.4378 61.8835 41.4378C57.6761 41.4378 54.1132 39.9975 51.1946 37.1168C48.3139 34.1982 46.8735 30.6352 46.8735 26.4279C46.8735 22.2206 48.3139 18.6766 51.1946 15.7959C54.1132 12.8773 57.6761 11.418 61.8835 11.418C66.0908 11.418 69.6538 12.8773 72.5723 15.7959C75.4909 18.6766 76.9502 22.2206 76.9502 26.4279C76.9502 30.6352 75.4909 34.1982 72.5723 37.1168ZM56.3685 32.0566C57.8467 33.5349 59.685 34.274 61.8835 34.274C64.0819 34.274 65.9202 33.5349 67.3985 32.0566C68.8767 30.5784 69.6158 28.7021 69.6158 26.4279C69.6158 24.1537 68.8767 22.2774 67.3985 20.7992C65.9202 19.3209 64.0819 18.5818 61.8835 18.5818C59.685 18.5818 57.8467 19.3209 56.3685 20.7992C54.9281 22.2774 54.2079 24.1537 54.2079 26.4279C54.2079 28.7021 54.9281 30.5784 56.3685 32.0566Z'
            fill='#06070E'
          />
          <path
            d='M36.4765 17.1046C37.1588 15.2853 38.2769 13.9207 39.831 13.011C41.4229 12.1013 43.1855 11.6465 45.1186 11.6465V19.8337C42.8822 19.5684 40.8733 20.0232 39.0919 21.1983C37.3483 22.3733 36.4765 24.3253 36.4765 27.0544V40.6429H29.1421V12.215H36.4765V17.1046Z'
            fill='#06070E'
          />
          <path
            d='M23.7657 8.33699H7.8461V17.775H23.3109V25.28H7.8461V40.6311H0V0.832031H23.7657V8.33699Z'
            fill='#06070E'
          />
        </svg>
        <p className='w-[70%] mx-auto mt-4 font-medium px-7'>
          Join us on a journey where your table is more than a meal – its an
          experience.
        </p>
      </div>
    </div>
  );
};

export default Hero;
