import { useState } from 'react';
// import { Link } from 'react-router-dom';
import MaxWidthWrapper from '../../components/MaxWidthWrapper';
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import mastercard from '../../images/icons/mastercard.png'
import visa from '../../images/icons/visa.png'

const CARDS = [
  {
    name: 'user name',
    type: 'Mastercard',
    imageSrc: mastercard,
    expiryDate: 'Card ending in .... 1234',
  },
  {
    name: 'user name',
    type: 'Visa',
    imageSrc: visa,
    expiryDate: 'Card ending in .... 1234',
  },
  {
    name: 'user name',
    type: 'Mastercard',
    imageSrc: mastercard,
    expiryDate: 'Card ending in .... 1234',
  },
  {
    name: 'user name',
    type: 'Visa',
    imageSrc: visa,
    expiryDate: 'Card ending in .... 1234',
  },
  {
    name: 'user name',
    type: 'Mastercard',
    imageSrc: mastercard,
    expiryDate: 'Card ending in .... 1234',
  },
]

const Transactions = () => {
  const [selectedMessage, setSelectedMessage] = useState<any>(null);
  return (
    <MaxWidthWrapper>
      <div className='order-page-header-wrapper'>
        <h3 className='text-base lg:text-2xl font-semibold'>Transaction cards</h3>
        <Dialog>
          <DialogTrigger className='w-fit h-[44px] checkout-btn'>
            Add new card
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"><circle cx="128" cy="128" r="112"/><path d="M 79.999992,128 H 176.0001"/><path d="m 128.00004,79.99995 v 96.0001"/></g></svg>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className='form-heading'>
                New message
              </DialogTitle>
              <form className='w-full space-y-5 mt-5 mb-12 text-gray-800'>
                <div className='w-full'>
                  <input
                    type='text'
                    id='type'
                    className='input-box'
                    placeholder='Subject'
                  />
                </div>
                <textarea id="chat" rows={4} className="resize-none input-box min-h-[50vh] focus-visible:!outline-none" placeholder="Message"></textarea>
                <button type="submit" className="!w-fit checkout-btn">
                  <span>Send</span>
                  <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                    <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                  </svg>
                  <span className="sr-only">Send message</span>
                </button>
              </form>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
      <section className='lg:h-[67vh] flex justify-bwteen md:space-x-6 xl:space-x-8 rounded-3xl'>
        <div className='w-full lg:w-[55%] xl:w-[50%] h-full overflow-hidden'>
          <div className='w-full lg:h-[62vh] pe-4 space-y-4 pb-3 overflow-y-auto scroll-style'>
          {CARDS.map((message: any, index) => (
            <div key={index} 
              className={`flex items-center w-full rounded-2xl space-x-4 drop-shadow bg-white px-3 py-5 hover:bg-green-200 cursor-pointer ${selectedMessage === message ? '!bg-green-200' : ''}`}
              onClick={() => setSelectedMessage(message)}
            >
              <img
                src={message.imageSrc}
                alt='imagem'
                width={1000}
                height={1000}
                className='object-cover w-[40px] h-[40px] rounded-full'
              />
              <div>
                <h4 className='text-xs font-semibold xl:text-sm'>{message.type.slice(0, 18)}{message.type.length > 18 ? '...' : ''}</h4>
                <p className='mt-2 font-medium text-xs'>{message.expiryDate.slice(0, 80)}{message.expiryDate.length > 80 ? '...' : ''}</p>
              </div>
            </div>
          ))}
          </div>
        </div>
          <div className='hidden lg:flex w-full justify-center min-h-full overflow-y-auto'>
            {selectedMessage ? (
              <div className='flex items-center w-full h-fit py-5 rounded-xl space-x-5 drop-shadow bg-white px-4 hover:bg-green-200 cursor-pointer'>
                <img
                  src={selectedMessage.imageSrc}
                  alt='imagemt'
                  width={1000}
                  height={1000}
                  className='object-cover w-[40px] h-[40px] rounded-full'
                />
                <div>
                  <h4 className='text-xs font-semibold xl:text-sm lg:text-justify'>{selectedMessage.type.slice(0, 18)}{selectedMessage.type.length > 18 ? '...' : ''}</h4>
                  <p className='mt-2 text-xs lg:text-justify'>{selectedMessage.expiryDate.slice(0, 80)}{selectedMessage.expiryDate.length > 80 ? '...' : ''}</p>
                  <p className='mt-2 text-xs lg:text-justify font-semibold capitalize'>{selectedMessage.name}</p>
                </div>
              </div>
            ) : (
              <div className='w-full h-full border flex flex-column justify-center items-center rounded-lg text-sm text-center text-gray-400'>No saved cards yet.</div>
            )}
          </div>
      </section>
    </MaxWidthWrapper>
  );
};

export default Transactions;
