import React, { useState } from "react";
import { Link } from "react-router-dom";
import blackLogo from "../../images/logos/black_logo.svg";
import Input, { ErrorInput } from "../../components/Input";
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading";

const ForgotPassword = () => {

    const [message, setMessage] = useState({ message: "", color: "" });
    const [style, setStyle] = useState("hidden");
    const [loading, setLoading] = useState(false);
    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<any>();
  
    const handleForgotPassword = async (values: any) => {
      setLoading(true);
      try {
        setStyle('flex')
      } catch (error) {
        setLoading(false);
        setMessage({ message: "Error occured", color: "text-red-500" });
      }
    };

  return (
    <section className="relative w-full flex justify-between  text-gray-900 py-10">
      <div
        id="toast-default"
        className={`${style} fixed inset-x-[40%] top-0 items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
        role="alert"
      >
        <div className={`ms-3 text-sm ${message.color} font-normal`}>
          {message.message}
        </div>
        <button
          type="button"
          className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-default"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
      <div className="w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%] flex justify-end">
        <Link
          to="/login"
          className="w-fit h-fit p-2 border border-neutral-300 rounded-full hover:text-white hover:bg-green-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M12.269 15.794a.75.75 0 0 1-1.06-.026l-5.002-5.25a.75.75 0 0 1 0-1.035l5.001-5.25a.75.75 0 1 1 1.086 1.034l-4.508 4.734l4.508 4.733a.75.75 0 0 1-.025 1.06"
            />
          </svg>
        </Link>
      </div>
      <div className="w-[80%] md:w-[70%] lg:w-[50%] xl:w-[40%] flex flex-col items-center">
        <Link to="/" className="py-4">
          <img
            src={blackLogo}
            alt="Taikez ecommerce logo"
            width={300}
            height={300}
            className="w-[7rem]"
          />
        </Link>
        <h4 className="text-xl font-bold mt-4 mb-3">Forgot Password</h4>
       
        <form className="w-full flex flex-col items-center space-y-8 m-8" onSubmit={handleSubmit(handleForgotPassword)}>
          <Input
            type="email"
            name="email"
            control={control}
            rules={{
              required: "Email is Required",
            }}
            id="email"
            placeholder="Email"
          />
          <ErrorInput error={errors?.email?.message} />
          
          <>
            {loading ? (
              <button type="submit" className="checkout-btn">
                <Loading loading={loading} />
              </button>
            ) : (
              <button type="submit" className="checkout-btn" >
                Submit
              </button>
            )}
          </>
      </form>
        </div>
      <div className="w-[10%] md:w-[10%] lg:w-[15%] xl:w-[20%]"></div>
    </section>
  )
}

export default ForgotPassword