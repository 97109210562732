import React, { ReactNode } from 'react';

interface ButtonProps {
  item: {
    btnTxt: string;
    svg?: ReactNode;
  };
  className?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ item, className, onClick}) => {
  return (
    <button className={`checkout-btn ${className}`} onClick={onClick}>
      {item.btnTxt}
      {item.svg && <span className='ml-1 inline-block'>{item.svg}</span>}
    </button>
  );
};

export default Button;
