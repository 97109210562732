import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import Breadcrumb from '../components/Breadcrumb';
import FarmFindsCard from '../components/FarmFindsCard';
import taikezProductImage from '../images/default_image.png';
import PriceFormatter from '../config/formatters/priceFormatter';
import CartService from '../config/services/cart.service';
import AlertModal from '../components/AlertModal';
import {
  useGetCartsQuery,
  usePostCartMutation,
  useRemoveCartMutation,
} from '../store/slices/api.slice';
import CookieHelper from '../config/utils/cookie.helper';
import { AppKeys } from '../config/utils/constants';

interface CartItem {
  id: number;
  name: string;
  price: number;
  image: string;
  quantity: number;
  sku: string;
  category_slug?: any;
}

const Cart = () => {
  const [showAlert, setShowAlert] = useState({ show: false, msg: '' }), navigate = useNavigate()
  const {
    data: cartItems,
    isSuccess: isSucessCarts,
    refetch,
  } = useGetCartsQuery('');
  const [postCart, { isLoading: loadingCart }] = usePostCartMutation();
  const [removeCart] = useRemoveCartMutation();
  const cartCookies = CookieHelper.get(AppKeys.CART) || [];

  const handleRemoveCart = async (item: string) => {
    try {
      const { data: responseData } = await removeCart(item);
      const { status, message } = responseData;
      if (status === true) {
        setShowAlert({ show: true, msg: message });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
        refetch();
      }
    } catch (error: any) {
      const { message } = error
      if(message == 'Error occurred, validation token not found'){
        navigate('/login')
      } else {
        setShowAlert({ show: true, msg: 'Network Error, Check your Internet' });
        setTimeout(
          () => setShowAlert((prevState) => ({ ...prevState, show: false })),
          4000
        );
      }
    }
  };

  // const updateCartItemQuantity = (sku: string, newQuantity: number) => {

  //     prevItems.map((item) =>
  //       item.sku === sku ? { ...item, quantity: newQuantity } : item
  //     )
  //   );
  // };

  const handleIncrement = async (sku: string) => {
    try {
      const body = {
        catalogue: [
          {
            sku: sku,
            quantity: '1',
          },
        ],
      };
      
      const response = await postCart(body).unwrap();
      
      if (response.status) {
        refetch();
      }
    } catch (error: any) {
        const { message } = error
        if(message == 'Error occurred, validation token not found'){
          navigate('/login')
        } else {
          setShowAlert({ show: true, msg: 'Network Error, Check your Internet' });
          setTimeout(
            () => setShowAlert((prevState) => ({ ...prevState, show: false })),
            4000
          );
        }
    }
  };

  // const handleDecrement = (sku: string) => {
  //   const item = cartItems.find((item) => item.sku === sku);
  //   if (item && item.quantity > 1) {
  //     updateCartItemQuantity(sku, item.quantity - 1);
  //   }
  // };

  const getTotalPrice = () => {
    let cartData;
    // console
    if(isSucessCarts === true){
      cartData = cartItems.data.reduce(
        (total: any, item: any) => total + item.price * item.quantity,
        0
      );
    }else{
      cartData = cartCookies.reduce(
        (total: any, item: any) => 
          total + item.price * item.quantity,
        0
      );
    }
    return cartData
  };

  const totalPrice = isSucessCarts ? (cartItems ? getTotalPrice() : 0) : (cartCookies ? getTotalPrice() : 0);

  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Cart', to: '/cart' },
  ];

  return (
    <>
      <MaxWidthWrapper>
        <Breadcrumb items={breadcrumbItems} />
        <div className='flex flex-col justify-between w-full pb-6 mt-10 text-gray-700 lg:flex-row-reverse gap-x-8 lg:gap-x-6 xl:gap-x-14 lg:mt-8 md:mb-14 lg:mb-20'>
          <div className='lg:sticky lg:top-20 h-fit w-full lg:max-w-[30%] lg:min-w-[38%] xl:min-w-[25%] text-start bg-white rounded-3xl shadow px-6 lg:px-6 xl:px-6 py-5 lg:py-4 md:pb-5 mb-10'>
            <div>
              <h3 className='mb-6 text-xl font-bold text-gray-900 capitalize'>
                Cart Summary
              </h3>
              <div className='space-y-2 text-sm'>
                <p className='cart-summary-li'>
                  <span className='text-sm font-semibold text-gray-600'>
                    Total
                  </span>
                  <span className='cart-value !text-lg !font-semibold '>
                    ₦ {PriceFormatter(totalPrice, false)}
                  </span>
                </p>
                <p className='text-xs text-gray-500 !my-4'>
                  Delivery fee not yet included.
                </p>
              </div>

              <Link to={isSucessCarts ? '/checkout' : '/login'} className='mt-8 mb-2 group checkout-btn'>
                Proceed to checkout
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  className='svg-transition'
                >
                  <path
                    fill='currentColor'
                    d='m15.038 6.343l-1.411 1.418l3.27 3.255l-13.605.013l.002 2l13.568-.013l-3.215 3.23l1.417 1.41l5.644-5.67z'
                  />
                </svg>
              </Link>
            </div>
          </div>

          {/* cart items wrapper */}
          <div className='w-full space-y-10 md:space-y-12 lg:space-y-12'>
            {isSucessCarts ? 
            // CartItems from API
            (cartItems.data.length > 0 ? (
              cartItems.data.map((cartItem: CartItem) => (
                <div key={cartItem.id}>
                  <div className='flex flex-col w-full md:flex-row gap-x-8 md:gap-x-5 lg:space-x-6 xl:space-x-16'>
                    <img
                      src={taikezProductImage}
                      alt='product image'
                      width={1000}
                      height={1000}
                      className='w-full md:w-[40%] lg:w-[70%] h-full md:h-[12rem] rounded-3xl object-cover'
                    />
                    <div className='w-full p-3 pt-3 mt-4 space-y-3 md:p-0 md:space-y-4 lg:pe-4 xl:space-y-4'>
                      <p className='text-base font-bold md:text-base'>
                        {cartItem.name} -{' '}
                        <span className='text-sm font-medium'>
                          {cartItem.quantity} units
                        </span>
                      </p>
                      <div className='w-full md:w-[60%] h-fit flex items-center justify-between gap-x-6 px-1 text-sm font-medium text-gray-900 rounded-full group bg-transparent border-2 border-gray-700'>
                        {/* Uncommented code */}
                        <button
                          type='button'
                          // onClick={() => handleDecrement(cartItem.sku)}
                          className='plus-minus-btn'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='w-5 h-5'
                            viewBox='0 0 48 48'
                          >
                            <path
                              fill='none'
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='4'
                              d='M10.5 24h28'
                            />
                          </svg>
                        </button>
                        <input
                          type='number'
                          value={cartItem.quantity}
                          readOnly
                          className='w-full text-center focus:outline-0 py-2 font-lora text-base text-gray-700 rounded-lg !bg-tkzBg'
                        />
                        <button
                          type='button'
                          onClick={() => handleIncrement(cartItem.sku)}
                          className='plus-minus-btn'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='w-5 h-5'
                            viewBox='0 0 16 16'
                          >
                            <path
                              fill='currentColor'
                              fillRule='evenodd'
                              d='M8 1.75a.75.75 0 0 1 .75.75v4.75h4.75a.75.75 0 0 1 0 1.5H8.75v4.75a.75.75 0 0 1-1.5 0V8.75H2.5a.75.75 0 0 1 0-1.5h4.75V2.5A.75.75 0 0 1 8 1.75'
                              clipRule='evenodd'
                            />
                          </svg>
                        </button>
                      </div>
                      <div className='w-full md:w-[60%] flex items-center justify-between'>
                        <p className='flex items-center my-2 text-base font-semibold gap-x-2'>
                          ₦ {PriceFormatter(cartItem.price, false)}
                        </p>
                        <button
                          onClick={() => handleRemoveCart(cartItem.sku)}
                          className='px-2 py-1 text-xs font-semibold text-red-500 rounded-full hover:cursor-pointer hover:bg-red-100'
                        >
                          Remove
                        </button>
                      </div>

                      <p className='flex items-center mt-2 text-xs font-medium gap-x-1 md:gap-x-none'>
                        <span>Market rate is 5% more </span>
                        <svg
                          width='30'
                          height='2'
                          viewBox='0 0 64 4'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2 2L62 2.00001'
                            stroke='#06070E'
                            strokeWidth='3'
                            strokeLinecap='round'
                          />
                        </svg>
                        <span className='text-xs text-green-500 whitespace-nowrap'>
                          saving ₦20
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='h-[50vh] flex flex-col justify-center p-8 text-center border border-gray-300'>
                <p className='text-sm text-gray-600'>No items in cart.</p>
              </div>
            )) : 
            (
              // Carts from Cookies
              (cartCookies.length > 0 ? (
                cartCookies.map((item: CartItem) => (
                  <div key={item.sku}>
                    <div className='flex flex-col w-full md:flex-row gap-x-8 md:gap-x-5 lg:space-x-6 xl:space-x-16'>
                      <img
                        src={taikezProductImage}
                        alt='product image'
                        width={1000}
                        height={1000}
                        className='w-full md:w-[40%] lg:w-[70%] h-full md:h-[12rem] rounded-3xl object-cover'
                      />
                      <div className='w-full p-3 pt-3 mt-4 space-y-3 md:p-0 md:space-y-4 lg:pe-4 xl:space-y-4'>
                        <p className='text-base font-bold md:text-base'>
                          {item.name} -
                          <span className='text-sm font-medium'>
                            {item.quantity} units
                          </span>
                        </p>
                        {/* <div className='w-full md:w-[60%] h-fit flex items-center justify-between gap-x-6 px-1 text-sm font-medium text-gray-900 rounded-full group bg-transparent border-2 border-gray-700'>
                          
                          <button
                            type='button'
                            className='plus-minus-btn'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-5 h-5'
                              viewBox='0 0 48 48'
                            >
                              <path
                                fill='none'
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='4'
                                d='M10.5 24h28'
                              />
                            </svg>
                          </button>
                          <input
                            type='number'
                            value={item.quantity}
                            readOnly
                            className='w-full text-center focus:outline-0 py-2 font-lora text-base text-gray-700 rounded-lg !bg-tkzBg'
                          />
                          <button
                            type='button'
                            onClick={() => handleIncrement(item.sku)}
                            className='plus-minus-btn'
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              className='w-5 h-5'
                              viewBox='0 0 16 16'
                            >
                              <path
                                fill='currentColor'
                                fillRule='evenodd'
                                d='M8 1.75a.75.75 0 0 1 .75.75v4.75h4.75a.75.75 0 0 1 0 1.5H8.75v4.75a.75.75 0 0 1-1.5 0V8.75H2.5a.75.75 0 0 1 0-1.5h4.75V2.5A.75.75 0 0 1 8 1.75'
                                clipRule='evenodd'
                              />
                            </svg>
                          </button>
                        </div> */}
                        <div className='w-full md:w-[60%] flex items-center justify-between'>
                          <p className='flex items-center my-2 text-base font-semibold gap-x-2'>
                            ₦ {PriceFormatter(item.price, false)}
                          </p>
                          {/* <button
                            onClick={() => handleRemoveCart(item.sku)}
                            className='px-2 py-1 text-xs font-semibold text-red-500 rounded-full hover:cursor-pointer hover:bg-red-100'
                          >
                            Remove
                          </button> */}
                        </div>
  
                        <p className='flex items-center mt-2 text-xs font-medium gap-x-1 md:gap-x-none'>
                          <span>Market rate is 5% more </span>
                          <svg
                            width='30'
                            height='2'
                            viewBox='0 0 64 4'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2 2L62 2.00001'
                              stroke='#06070E'
                              strokeWidth='3'
                              strokeLinecap='round'
                            />
                          </svg>
                          <span className='text-xs text-green-500 whitespace-nowrap'>
                            saving ₦20
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='h-[50vh] flex flex-col justify-center p-8 text-center border border-gray-300'>
                  <p className='text-sm text-gray-600'>No items in cart.</p>
                </div>
              ))
            )
          }
          </div>
        </div>

        {/* <FarmFindsCard categorySlug={cartItems[0]?.category_slug} /> */}
        {showAlert.show && (
          <AlertModal
            msg={showAlert.msg}
            click={() =>
              setShowAlert((prevState) => ({ ...prevState, show: false }))
            }
          />
        )}
      </MaxWidthWrapper>
    </>
  );
};

export default Cart;
