import React from 'react'

const CategorySkeleton = () => {
  return (
    <div className='w-10 h-10 md:w-[4rem] md:h-[4rem] lg:w-[5rem] lg:h-[5rem] m-auto bg-neutral-200 shadow animate-pulse rounded-full'>
      <div className='w-full h-full flex items-center justify-center bg-neutral-200 rounded-full'>
        <svg
          width='26'
          height='13'
          viewBox='0 0 26 13'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='w-4 h-4 md:w-6 md:h-6'
        >
          <path
            d='M12.7793 6.10933C12.7793 4.69185 13.2214 3.37856 13.9743 2.29809C14.6445 1.33777 13.9799 0.0075855 12.8093 0.00195312C12.799 0.00195312 12.7896 0.00195312 12.7793 0.00195312C11.7729 0.00195312 10.8229 0.246962 9.98465 0.679716C9.89078 0.72853 9.79785 0.779222 9.70679 0.83273C9.61761 0.88436 9.53031 0.938805 9.44394 0.996068C7.77582 2.08687 6.67188 3.97184 6.67188 6.11027C6.67188 8.24869 7.77582 10.1337 9.44394 11.2235C9.53031 11.2808 9.61761 11.3352 9.70679 11.3869C9.79691 11.4404 9.88984 11.4911 9.98371 11.5399C10.822 11.9726 11.7729 12.2176 12.7793 12.2176C12.7896 12.2176 12.799 12.2176 12.8093 12.2176C13.9808 12.212 14.6445 10.8818 13.9743 9.92057C13.2205 8.84104 12.7793 7.52681 12.7793 6.11027V6.10933Z'
            fill='#A3A3A3'
          />
          <path
            d='M6.10738 6.10933C6.10738 4.69185 6.54952 3.37856 7.30238 2.29809C7.97263 1.33777 7.30801 0.0075855 6.13742 0.00195312C6.12709 0.00195312 6.1177 0.00195312 6.10738 0.00195312C5.10106 0.00195312 4.15106 0.246962 3.31278 0.679716C3.2189 0.72853 3.12597 0.779222 3.03492 0.83273C2.94574 0.88436 2.85844 0.938805 2.77207 0.996068C1.10395 2.08687 0 3.97184 0 6.11027C0 8.24869 1.10395 10.1337 2.77207 11.2235C2.85844 11.2808 2.94574 11.3352 3.03492 11.3869C3.12503 11.4404 3.21797 11.4911 3.31184 11.5399C4.15013 11.9726 5.10106 12.2176 6.10738 12.2176C6.1177 12.2176 6.12709 12.2176 6.13742 12.2176C7.30895 12.212 7.97263 10.8818 7.30238 9.92058C6.54858 8.84104 6.10738 7.52681 6.10738 6.11027V6.10933Z'
            fill='#D4D4D4'
          />
          <path
            d='M19.4511 0C18.4448 0 17.4938 0.245009 16.6555 0.677763C16.5617 0.726577 16.4687 0.777269 16.3786 0.830777C16.2894 0.882407 16.2021 0.936855 16.1158 0.994118C14.4477 2.08492 13.3428 3.96989 13.3428 6.10832C13.3428 8.24675 14.4477 10.1317 16.1158 11.2216C16.2021 11.2788 16.2894 11.3333 16.3786 11.3849C16.4687 11.4384 16.5617 11.4891 16.6555 11.537C17.4938 11.9707 18.4448 12.2157 19.4511 12.2157C22.8183 12.2157 25.5585 9.47554 25.5585 6.10832C25.5585 2.7411 22.8183 0 19.4511 0Z'
            fill='#D4D4D4'
          />
        </svg>
      </div>
      <div className='w-8 h-1 md:w-16 md:h-2 bg-neutral-200 rounded-full mx-auto mt-1.5'></div>
      <span className='sr-only'>Loading...</span>
    </div>
  );
};

export default CategorySkeleton;
