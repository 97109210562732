import Breadcrumb from 'src/components/Breadcrumb';
import AboutUsPhoto from 'src/images/pages/about_us.png';
import DeliveryBox from 'src/images/pages/delivery_box.png';
import DeliveryVan from 'src/images/pages/delivery_van.png';
import ExploreBtn from './../components/Button';
import MaxWidthWrapper from './../components/MaxWidthWrapper';
import { Link } from 'react-router-dom';

const Aboutus = () => {
  const breadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'About us', to: '' },
  ];

  const buttonItem = {
    link: '/',
    btnTxt: 'Explore delightful food items',
  };

  return (
    <div className='mb-4 text-gray-700 md:mb-16'>
      <div className='flex justify-center bg-neutral-100'>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <MaxWidthWrapper className='flex items-center justify-center w-full py-6 bg-neutral-100 lg:pb-8'>
        <div className='w-full md:w-[50%] lg:w-[50%] xl:w-[60%] flex flex-col lg:flex-row lg:gap-x-12 xl:gap-x-14 items-center'>
          <div>
            <img
              src={AboutUsPhoto}
              alt='customer representative on a call with customer'
              width={1000}
              height={1000}
              className='object-cover w-full h-[150px] md:h-full rounded-lg md:rounded-xl'
            />
          </div>
          <article className='mt-5 text-center w-fit lg:mt-0'>
            <p className='text-sm font-medium'>
              Fresh finds await!
              <br />
              Browse effortlessly.
              <br />
              We bring the market to you.
              <br />
              Simple, trustworthy.
              <br />
            </p>
            <h4 className='mt-6 mb-6 text-lg italic font-bold'>
              “Your Culinary Compass –<br />
              Bringing Ingredients to
              <br />
              Your Doorstep.”
            </h4>

            <Link to={buttonItem.link} className='mt-12'>
              <button className='checkout-btn'>{buttonItem.btnTxt}</button>
            </Link>
          </article>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col about-us-width lg:flex-row'>
        <div className='about-us-img-wrapper'>
          <img
            src={DeliveryBox}
            alt='Taikez packaging box'
            width={1000}
            height={1000}
            className='w-full about-us-img'
          />
        </div>
        <div className='w-full'>
          <h5 className='mt-4 mb-4 text-sm font-medium'>Our vision</h5>
          <h4 className='mb-6 text-lg font-bold leading-tight lg:mb-4'>
            Cultivating Tomorrow‘s
            <br />
            Culinary Landscape
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-[50%] text-sm'>
            Elevating Lives Through Effortless Connections: TAIKEZ envisions a
            world where shopping for farm-fresh ingredients is easy, enjoyable,
            and empowers both farmers and vendors. We strive to seamlessly
            connect farmers and ingredient vendors to customers, fostering
            prosperity for producers, affordability for shoppers, and a
            delightful, stress-free shopping experience for all.
          </p>
        </div>
      </MaxWidthWrapper>

      <MaxWidthWrapper className='flex-col-reverse bg-white about-us-width lg:flex-row'>
        <div className='w-full xl:w-[40%]'>
          <h5 className='mt-5 mb-4 text-sm font-medium'>Our mission</h5>
          <h4 className='mb-4 text-lg font-bold leading-tight'>
            Empowering Connections,
            <br />
            Nourishing Lives
          </h4>
          <p className='w-full md:w-[80%] lg:w-[90%] xl:w-full text-sm'>
            At TAIKEZ, our mission is to revolutionize the way you shop for food
            items and farm products. We are committed to creating a platform
            that effortlessly links farmers and vendors to customers, ensuring
            farmers and vendors thrive economically while providing customers
            with quick, affordable, and easy access to the freshest ingredients.
            Through simplicity, affordability, and connectivity, we aim to make
            every shopping experience with TAIKEZ a rewarding journey for all
            involved.
          </p>
        </div>
        <div className='mt-10 about-us-img-wrapper'>
          <img
            src={DeliveryVan}
            alt='Taikez delivery van'
            width={1000}
            height={1000}
            className='about-us-img xl:w-[70%]'
          />
        </div>
      </MaxWidthWrapper>
      <MaxWidthWrapper className='w-full md:w-[80%] xl:max-w-[42%] text-center pt-8 lg:pt-24'>
        <h5 className='mb-4 text-sm font-medium'>Our brand story</h5>
        <h4 className='mb-4 text-lg font-bold'>The TAIKEZ Journey</h4>
        <div className='space-y-4 text-sm text-justify'>
          <p>
            In the heart of our story lies a passion for transforming the way we
            access and enjoy farm fresh ingredients. TAIKEZ emerged from a
            collective vision fueled by a desire to bridge the gap between local
            farmers, food ingredient vendors, and eager customers.
          </p>
          <p>
            It all started during a candid conversation among our founders. As
            they navigated through the challenges faced by local farmers and
            food vendors, a spark ignited. The realization dawned that a
            significant opportunity lay in simplifying the intricate process of
            bringing fresh produce from the fields to the tables of families
            across the globe.
          </p>
          <p>
            The founding team, inspired by a shared commitment to empower local
            communities and enhance the livelihoods of farmers, embarked on a
            journey to create TAIKEZ. They envisioned a platform that not only
            facilitated seamless connections between farmers, vendors, and
            customers but also championed affordability, accessibility, and
            simplicity in every transaction.
          </p>
          <p>
            One pivotal moment in our story unfolded during a visit to a local
            farm. Witnessing the hard work and dedication of farmers cultivating
            the land left an indelible mark on our founders. It became clear
            that TAIKEZ had a mission – to uplift the lives of these unsung
            heroes and empower them economically.
          </p>
          <p>
            TAIKEZ emerged as a response to the dual challenges faced by farmers
            struggling to reach a broader market and customers yearning for a
            convenient way to access fresh, quality ingredients. The brand was
            born with the conviction that by making this connection seamless, we
            could not only empower farmers and vendors but also redefine the
            culinary experience for every home.
          </p>
          <p>
            With this vision, TAIKEZ came into existence – an embodiment of
            simplicity, friendliness, and a commitment to connect fields to
            families. Our story is woven with the threads of empowerment,
            innovation, and a relentless pursuit of making a positive impact in
            the lives of those who cultivate the essence of our nourishment.
          </p>
          <p>
            As we journey forward, we invite you to be a part of the TAIKEZ
            story, where every ingredient tells a tale of connection,
            empowerment, and the joy of farm fresh abundance.
          </p>
        </div>
      </MaxWidthWrapper>
    </div>
  );
};

export default Aboutus;
