import React, { useState, useEffect } from 'react';
import CategoryService from '../config/services/category.service';
import { Link } from 'react-router-dom';

// category svgs
import vegetablesSVG from 'src/images/cate_icons/vegetables.png';
import TubersSVG from 'src/images/cate_icons/tubers.png';
import beveragesSVG from 'src/images/cate_icons/beverages.png';
import floursSVG from 'src/images/cate_icons/flours.png';
import fruitsSVG from 'src/images/cate_icons/fruits.png';
import grainsSVG from 'src/images/cate_icons/grains.png';
import groceriesSVG from 'src/images/cate_icons/groceries.png';
import meatsSVG from 'src/images/cate_icons/meats.png';
import nutsSVG from 'src/images/cate_icons/nuts.png';
import dairySVG from 'src/images/cate_icons/dairy.png';
import oilsSVG from 'src/images/cate_icons/oils.png';

import CategorySkeleton from './CategorySkeleton';
import { useGetCategoriesQuery } from '../store/slices/api.slice';

interface Category {
  id: string;
  name: string;
  slug: string;
}

const FeaturedCat = ({title}: any) => {
  // category svgs
  const categorySVGs: { [key: string]: string } = {
    Vegetables: vegetablesSVG,
    Beverages: beveragesSVG,
    Tubers: TubersSVG,
    Flours: floursSVG,
    Fruits: fruitsSVG,
    Grains: grainsSVG,
    Groceries: groceriesSVG,
    Meats: meatsSVG,
    Nuts: nutsSVG,
    Dairy: dairySVG,
    oils: oilsSVG,
  };

  // fetch categories
  const { data: categories, isLoading, isSuccess } = useGetCategoriesQuery('');

  // sliding categories feature
  const itemsPerPage = 10; // Number of items to show per slide
  const [activeSlide, setActiveSlide] = useState(0);

  const totalSlides = Math.ceil(
    (isSuccess ? categories.data.length : 0) / itemsPerPage
  );

  const handlePrevClick = () => {
    setActiveSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const handleNextClick = () => {
    setActiveSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  const handleDotClick = (index: number) => {
    setActiveSlide(index);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className='flex flex-col items-center w-full my-9'>
      <h2 className='section-heading-2 mb-7'>{title}</h2>
      <div className='items-center justify-between w-full md:flex'>
        <button
          className='hidden featuredCat-btn lg:-right-10 xl:-right-20 z-1 lg:flex'
          onClick={handlePrevClick}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={2}
            stroke='currentColor'
            className='w-4 h-4'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15.75 19.5L8.25 12l7.5-7.5'
            />
          </svg>
        </button>
        <div className='w-full md:w-full lg:w-[70%] xl:w-[60%]flex flex-col justify-center items-center'>
          {isLoading || isSuccess == false ? (
            <div className='grid w-full h-full grid-cols-5 grid-rows-2 px-2 py-4 pb-8 overflow-hidden bg-white rounded-lg shadow xl:px-6 md:pt-10 md:pb-12 md:shadow xl:gap-x-12 gap-y-4 md:gap-y-10'>
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
              <CategorySkeleton />
            </div>
          ) : (
            <div className='categorySliderWrapper'>
              {categories.data.map((category: any) => (
                <div
                  key={category.id}
                  className='transition-all duration-500 ease-in-out transform'
                >
                  <Link
                    to={`/category/${category.slug}`}
                    className='flex flex-col items-center md:w-[7rem] text-center transition ease-in-out duration-300 hover:text-green-700 gap-y-2 text-xs md:text-sm font-medium text-gray-600 justify-center'
                  >
                    <div className='w-12 h-12 md:w-16 md:h-16 xl:w-[5rem] xl:h-[5rem] rounded-full overflow-hidden'>
                      <img
                        src={categorySVGs[category.name]}
                        alt={category.name}
                        width={200}
                        height={200}
                        className='object-cover transition ease-in-out duration-300 hover:-translate-y-1 scale-105 hover:scale-125 h-[7rem] drop-shadow-md rounded-full'
                      />
                    </div>
                    <span>{category.name}</span>
                  </Link>
                </div>
              ))}
            </div>
          )}
          <div className='flex flex-col items-center justify-center space-x-3 mt-7'>
            {Array.from({ length: totalSlides }).map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === activeSlide ? 'bg-green-600' : 'bg-white'
                }`}
                onClick={() => handleDotClick(index)}
              ></button>
            ))}
          </div>
        </div>
        <button
          className='hidden featuredCat-btn lg:-left-10 xl:-left-20 lg:flex'
          onClick={handleNextClick}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={2}
            stroke='currentColor'
            className='w-4 h-4'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M8.25 4.5l7.5 7.5-7.5 7.5'
            />
          </svg>
        </button>
      </div>
    </section>
  );
};

export default FeaturedCat;
