import CartService from '../config/services/cart.service';
import PriceFormatter from '../config/formatters/priceFormatter';
import React, { useEffect, useState } from 'react';
import taikezProductImage from '../images/default_image.png';
import { Link, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { useConfirmPaymentMutation } from '../store/slices/api.slice';

interface CartItem {
  title: string;
  deliveryTxt: string;
  couponTxt: string;
  totalTxt: string;
  deliveryFee: number;
  subTotalAmt: number;
  couponAmt: number;
  totalAmt: number;
  optionalTxt: string;
  link: string;
  productLink: string;
  btnTxt: string;
}

interface CartSummaryProps {
  items: any[]; // Make items prop optional
  address?: any
  isSuccess?: any
}

const CartSummary: React.FC<CartSummaryProps> = ({ items, address, isSuccess }) => {
  const [loading, setLoading] = useState(false), navigate = useNavigate();
  const [itemData, setItemData] = useState([]);
  const [confirmPayment] = useConfirmPaymentMutation()
  // calculate sub total price of cart items
  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (items.length > 0) {
      items.forEach((item) => {
        subTotalPrice += item?.price * item?.quantity;
      });
    }

    return subTotalPrice;
  };

  // calculate total price of cart items
  const getTotalPrice = () => {
    let totalPrice = 0;
    if (items.length > 0) {
      items.forEach((item) => {
        const deliveryFee =
          item.deliveryFee == undefined ? 0 : item?.deliveryFee;
        totalPrice += item?.price * item?.quantity + deliveryFee;
      });
    }

    return totalPrice;
  };

  const handleConfirmPayment = async (e: any) => {
    e.preventDefault()
    try {
      setLoading(true);
      const values = {
        catalogue: items.map((item) => ({
          sku: item.sku,
          quantity: item.quantity,
        })),

        // "coupon": "21DAYS",
        callback_url: window.location.origin,
        location: {
          address: address.address,
          city: address.city,
          state: address.state,
        },
      };
      const { data: responseData } = await confirmPayment(values);
      if (responseData.status === true) {
        window.location.href = responseData.data.checkout_url;
      }
      setLoading(false);
    } catch  (error: any) {
      const {message} = error
      if(message == 'Error occurred, validation token not found'){
        navigate('/login')
      } else {
        navigate('/')
      }
    
      setLoading(false);
    }
  };

  return (
    <div className='lg:sticky lg:top-20 w-full h-fit lg:max-w-[30%] lg:min-w-[38%] xl:min-w-[25%] text-start bg-white rounded-3xl shadow px-6 lg:px-6 xl:px-6 py-5 lg:py-4 md:pb-5 mb-[100px]'>
      <h3 className='mb-6 text-xl font-bold text-gray-900 capitalize'>
        Cart Summary
      </h3>
      {isSuccess ? (
        <>
          {items.map((item, index) => (
            <div className='space-y-2 text-sm'>
              <div className='mb-6 gap-y-4' key={index}>
                <Link
                  key={index}
                  to={`/product/${item.sku}`}
                  className='flex flex-col justify-start gap-x-4 md:flex-row md:items-center lg:gap-x-3 xl:gap-x-4'
                >
                  <div className='w-full md:w-[30%] lg:min-w-[30%] xl:min-w-[35%] mb-4 md:mb-0'>
                    <img
                      src={taikezProductImage}
                      alt='product inside cart'
                      width={500}
                      height={500}
                      className='object-cover w-full border rounded-xl'
                    />
                  </div>
                  <div className='ms-4'>
                    <div className='flex items-center gap-x-2'>
                      <p className='font-semibold capitalize text-wrap lg:text-sm'>
                        {item.name}
                      </p>
                      <p className='text-sm text-nowrap'>
                        ({item.quantity} {item.quantity > 1 ? 'items' : 'item'})
                      </p>
                    </div>
                    <p className='text-sm font-medium'>
                      ₦ {PriceFormatter(item.price, false)}
                    </p>
                  </div>
                </Link>
              </div>
              <p className='text-xs text-gray-500 !my-4'></p>
            </div>
          ))}
          <div className='space-y-4'>
            <p className='cart-summary-li'>
              <span className='text-gray-600'>Sub Total</span>
              <span className='cart-value'>
                ₦{' '}
                {items.length > 0
                  ? PriceFormatter(getSubTotalPrice(), false)
                  : 0}
              </span>
            </p>
            <p className='cart-summary-li'>
              <span className='text-gray-600'>Coupon</span>
              <span className='w-[25%] lg:w-[45%] xl:w-[40%]'>
                <input
                  type='text'
                  aria-describedby='helper-text-explanation'
                  className='w-full px-2 py-1 text-base font-semibold text-gray-700 border rounded-md text-end border-neutral-300 bg-neutral-50 placeholder:text-xs placeholder:font-normal placeholder:text-neutral-600 focus:ring-neutral-100 focus:border-neutral-100'
                  placeholder='Coupon code'
                />
              </span>
            </p>
            <p className='cart-summary-li'>
              <span className='text-sm font-semibold text-gray-600'>Total</span>
              <span className='cart-value'>
                ₦{' '}
                {items.length > 0 ? PriceFormatter(getTotalPrice(), false) : 0}
              </span>
            </p>
            {loading ? (
              <button type='submit' className='mt-8 mb-2 checkout-btn'>
                <Loading loading={loading} />
              </button>
            ) : (
              <button
                onClick={handleConfirmPayment}
                className='mt-8 mb-2 group checkout-btn'
              >
                Pay Now
              </button>
            )}
          </div>
        </>
      ) : (
        <p className='text-center text-slate-400'>No Cart </p>
      )}
    </div>
  );
};

export default CartSummary;
